import { createApiResource, SearchParams } from '../../../api/createApiResource';
import { SchemaPage } from '../../../sharedComponents/schemaPage';
import LocationDerivationIconPath from '../../../icons/location-derivation.svg';
import { NetworkLocation } from './networkLocation.interfaces';

const resource = createApiResource<NetworkLocation, SearchParams>({
  name: 'networkLocation',
  search: ({
    pageNumber,
    pageSize,
    filters
  }: SearchParams) => {
    return {
      method: 'GET',
      url: `/NetworkLocation?${new URLSearchParams({
        page_number: pageNumber,
        page_size: pageSize,
        ...(filters?.geographic_location ? { network_location_filter: filters.geographic_location } : {}),
        ...(filters?.subtenant ? { subtenant_filter: filters?.subtenant } : {})
      }).toString()}`,
      params: {},
      responseFormatter: (response) => {
        return response.then((data) =>
          ({   
            pageCount: data.page_count,
            recordCount: data.record_count,
            data: data?.search_response?.map(({roaming_classification, ...item }:NetworkLocation) => ({
              ...item,
              roaming_classification: roaming_classification ? JSON.stringify(roaming_classification) : undefined
            }))
          })
        );
      }
    };
  },
  create: ({roaming_classification, ...data}) => {
    return {
      url: '/NetworkLocation',
      method: 'POST',
      body: JSON.stringify({
        ...data,
        roaming_classification: roaming_classification ? JSON.parse(roaming_classification) : undefined
      })
    };
  },
  update: ({roaming_classification, ...data}) => {
    return {
      url: '/NetworkLocation',
      method: 'PUT',
      body: JSON.stringify({
        ...data,
        roaming_classification: roaming_classification ? JSON.parse(roaming_classification) : undefined
      })
    };
  },
  remove: (data) => {
    if (data.subtenant) {
      return {
        url: `/NetworkLocation/${encodeURIComponent(data.geographic_location)}/${encodeURIComponent(data.subtenant)}`
      };
    }
    return {
      url: `/NetworkLocation/${encodeURIComponent(data.geographic_location)}`
    };
  },
  entitySpecUpdate: () => ({
    method: 'GET',
    url: '/EntitySpec/NetworkLocation'
  })
});

const netwrokLocationSchema = {
  Schema: {
    properties: {
      geographic_location: {
        readOnly: true
      },
      subtenant: {
        readOnly: true
      }
    }
  }
};

export function NetworkLocationList() {
  return (
    <SchemaPage
      apiResource={resource}
      entitySpecOverrides={netwrokLocationSchema}
      FormProps={{
        uiSchema: {
          'ui:order': [
            'geographic_location',
            'location',
            'country',
            'rate_zone',
            'time_zone',
            'time_zone_display_name',
            'time_zone_dst_display_name',
            'time_zone_iana_name',
            'pcode',
            'location_category',
            'roaming_classification',
            'subtenant',
            'category',
            'address_info'
          ],
          'roaming_classification': {
            "ui:widget": "textarea",
            "ui:options": {
              'rows': 3
            }
          },
        },
      }}
      DataListProps={{
        glyphPath: LocationDerivationIconPath,
        searchTermOptions: [
          { label: 'Geographic Location', value: 'geographic_location' },
          { label: 'Subtenant Id', value: 'subtenant' }
        ],
        pageTitle: 'Network Location',
        DataGridProps: {
          getRowId: (row) => row.geographic_location,
          columns: [
            {
              field: 'geographic_location',
              headerName: 'Geographic Location',
              flex: 2
            },
           
            {
              field: 'location',
              headerName: 'Location',
              flex: 1
            },
            {
              field: 'country',
              headerName: 'Country',
              flex: 1
            },
            {
              field: 'rate_zone',
              headerName: 'Rate Zone',
              flex: 1
            },
            {
              field: 'time_zone',
              headerName: 'Time Zone',
              flex: 1
            },
            {
              field: 'time_zone_iana_name',
              headerName: 'IANA Time Zone',
              flex: 1
            },
            {
              field: 'location_category',
              headerName: 'Location Category',
              flex: 1
            },
            {
              field: 'subtenant',
              headerName: 'Subtenant',
              flex: 1
            }
          ]
        }
      }}
    />
  );
}
