import { createApiResource, SearchParams } from '../../api/createApiResource';
import { SchemaPage } from '../../sharedComponents/schemaPage';
import SpecialNumberIconPath from '../../icons/special-number.svg';
import { SpecialNumber } from './specialNumber.interfaces';

const resource = createApiResource<SpecialNumber, SearchParams>({
  name: 'specialNumber',
  search: ({ pageNumber, pageSize, filters }: SearchParams) => {
    return {
      method: 'GET',
      url: `/SpecialNumber?${new URLSearchParams({
        page_number: pageNumber,
        page_size: pageSize,
        ...(filters?.special_number
          ? { special_number_filter: filters?.special_number }
          : {}),
        ...(filters?.subtenant ? { subtenant_filter: filters?.subtenant } : {})
      }).toString()}`,
      params: {},
      responseFormatter: (response) => {
        return response.then((data) => ({
          pageCount: data.page_count,
          recordCount: data.record_count,
          data: data?.search_response
        }));
      }
    };
  },
  create: () => {
    return {
      url: '/SpecialNumber',
      method: 'POST'
    };
  },
  update: () => {
    return {
      url: '/SpecialNumber',
      method: 'PUT'
    };
  },
  remove: (data) => {
    if (data.subtenant) {
      return {
        url: `/SpecialNumber/${encodeURIComponent(data.special_number)}/${
          data.subtenant
        }`
      };
    }
    return {
      url: `/SpecialNumber/${encodeURIComponent(data.special_number)}`
    };
  },
  entitySpecUpdate: () => ({
    method: 'GET',
    url: '/EntitySpec/SpecialNumber'
  })
});

const specialNumberSchema = {
  Schema: {
    properties: {
      special_number: {
        readOnly: true
      },
      subtenant: {
        readOnly: true
      }
    }
  }
};

export function SpecialNumberList() {
  return (
    <SchemaPage
      apiResource={resource}
      entitySpecOverrides={specialNumberSchema}
      FormProps={{
        uiSchema: {
          'ui:order': [
            'special_number',
            'description',
            'rate_zone',
            'pcode',
            'time_zone',
            '*',
            'address_info'
          ]
        }
      }}
      DataListProps={{
        glyphPath: SpecialNumberIconPath,
        searchTermOptions: [
          { label: 'Special Number', value: 'special_number' },
          { label: 'Subtenant Id', value: 'subtenant' }
        ],
        pageTitle: 'Special Number',
        DataGridProps: {
          getRowId: (row) => row.special_number,
          columns: [
            {
              field: 'special_number',
              headerName: 'Special Number',
              flex: 1
            },

            {
              field: 'address_info',
              headerName: 'Address Information',
              renderCell: ({ row: item }) => JSON.stringify(item.address_info),
              flex: 2
            },
            {
              field: 'description',
              headerName: 'Description',
              flex: 1
            },
            {
              field: 'rate_zone',
              headerName: 'RateZone',
              flex: 1
            },
            {
              field: 'pcode',
              headerName: 'Pin Code',
              flex: 0.5
            },
            {
              field: 'category',
              headerName: 'Category',
              flex: 1
            }
          ]
        }
      }}
    />
  );
}
