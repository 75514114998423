import { starcConfigResource } from '../../../api/createApiResource';
import { SearchConfigurationFileLoaderRequest } from '../configurationFileLoader.interface';
import { BulkUploadRequest, CompleteMultipartRequest, MultipartUploadRequest, StartMultiPartRequest } from './configurationFileLoader.BulkUpload.interface';

export const startMultiPartUpload = ({ fileName }: StartMultiPartRequest) => {
      return starcConfigResource({
        method: 'POST',
        url: '/StartMultiPartUpload',
        data: {
          file_name: fileName
        }
      });
    };


export const multipartUpload = ({
  fileName,
  uploadId,
  partNumber,
  inputData
}: MultipartUploadRequest) => {
  const formData = new FormData();
  formData.append('FileName', fileName);
  formData.append('UploadId', uploadId);
  formData.append('PartNumber', `${partNumber}`);
  formData.append('InputData', inputData);

      return starcConfigResource({
        method: 'PUT',
        url: '/MultiPartUpload',
        body: formData,
        excludeContentTypeHeader: true
      });
};


export const completeMultipartUpload = ({
  fileName,
  uploadId,
  partTags
}: CompleteMultipartRequest) => {
      return starcConfigResource({
        method: 'POST',
        url: '/CompleteMultiPartUpload',
        data: {
          file_name: fileName,
          upload_id: uploadId,
          part_tags: partTags
        }
      });
};


export const bulkUpload = ({
  fileName,
  operation,
  emailAddress
}: BulkUploadRequest) => {

      return starcConfigResource({
        method: 'POST',
        url: '/BulkUpload',
        data: {
          file_name: fileName,
          operation,
          email_address: emailAddress
        }
  });
};

export const searchConfigurationFileLoader = ({
    pageSize,
    pageNumber
  }: SearchConfigurationFileLoaderRequest) => {
  
        return starcConfigResource({
          method: 'GET',
          url: '/BulkLoadHistory',
          data: {
            page_number: pageNumber,
            page_size: pageSize,
          }
    });
  };