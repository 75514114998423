import { LOGIN_PATH } from './../../AppRouter';
import { sendToLogin } from './auth.helpers';
import { User } from './Login';
import { readToSessionStorage, writeToSessionStorage } from './session.storage';

export interface authenticationPayload extends User {
  refreshToken?: string | null;
}

function getLoginUrl(){
  return  sessionStorage.getItem('starc-login-url') && JSON.parse(sessionStorage.getItem('starc-login-url') as string);
}

function getClientSecret() {
  return (
    sessionStorage.getItem('starc-client-secret') &&
    JSON.parse(sessionStorage.getItem('starc-client-secret') as string)
  );
}

function getClientId() {
  return (
    sessionStorage.getItem('starc-client-id') &&
    JSON.parse(sessionStorage.getItem('starc-client-id') as string)
  );
}
export async function authenticateLogin(data: authenticationPayload) {
  const loginUrl = getLoginUrl();
  try {
    const response = await fetch(
      loginUrl,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams(
          !data.refreshToken
            ? {
                username: data.name,
                password: data.password,
                scope: 'openid',
                client_secret: getClientSecret(),
                client_id: getClientId(),
                grant_type: 'password'
              }
            : {
                client_secret: getClientSecret(),
                client_id: getClientId(),
                grant_type: 'refresh_token',
                refresh_token: data.refreshToken
              }
        )
      }
    );
    return response.json().then((response) => {
      if (response?.error) {
        return Promise.reject(response);
      } else if (response.access_token) {
        writeToSessionStorage('starc-sessionId', response.access_token);
        writeToSessionStorage('starc-expiry-time', response.expires_in);
        writeToSessionStorage('starc-session-state', response.session_state);
        writeToSessionStorage('starc-refresh-token', response.refresh_token);
        if (!data.refreshToken) {
          window.location.replace(
            sessionStorage.getItem('strac-authentication-redirect') &&
              (readToSessionStorage('strac-authentication-redirect') as any)
          );
        }
      }
    });
  } catch (e) {
    alert('Service Unavailable');
    sendToLogin(LOGIN_PATH, undefined, true);
  }
}
