import { useState } from 'react';
import { DataList } from '../../sharedComponents/dataList';
import PriceMappingIconPath from '../../icons/price-mapping.svg';
import { createApiResource, SearchParams } from '../../api/createApiResource';
import { Box } from '@mui/material';
import ConfigurationBulkUpload from './BulkUpload/configurationFileLoader.BulkUpload';
import { getConfigurationFileLoaderStatuses } from './configurationFileLoader.constants';
import { Status } from '../../sharedComponents/Status';
import { DateHelpers } from '../../helpers/dateHelpers';

export default function ConfigurationFileLoader() {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const configurationFileLoaderStatuses = getConfigurationFileLoaderStatuses();
  function openconfigurationfileloaderpopup() {
    setModalOpen(true);
  }

  const DataListProps = {
    createButtonText: 'Add Configuration File',
    onCreateButtonClick: openconfigurationfileloaderpopup,
    allowRefresh: true,
    glyphPath: PriceMappingIconPath,
    pageTitle: 'Configuration File Loader',
    searchTermOptions: [
      { label: 'Batch Id', value: 'batch_id' },
      { label: 'File Name', value: 'file_name' }
    ],
    DataGridProps: {
      getRowId: (row: any) => row.batch_id,
      columns: [
        {
          field: 'start_time',
          headerName: 'Process Date',
          renderCell: ({ row }: any) => {
            return (
              <Box maxWidth="12.5rem">
                {DateHelpers.formatDate(row.start_time, true)}
              </Box>
            );
          }
        },
        {
          field: 'batch_id',
          headerName: 'Batch Id',
          flex: 3
        },
        {
          field: 'file_name',
          headerName: 'File Name'
        },
        {
          field: 'status',
          headerName: 'Status',
          renderCell: ({ row }: any) => {
            return (
              <Status
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...configurationFileLoaderStatuses.filter(
                  (configurationFileLoaderStatus) =>
                    configurationFileLoaderStatus.status === row.status
                )[0].value}
              />
            );
          }
        },
        {
          field: 'end_time',
          headerName: 'Completion Date',
          renderCell: ({ row }: any) => {
            return (
              <Box maxWidth="12.5rem">
                {DateHelpers.formatDate(row.end_time, true)}
              </Box>
            );
          }
        }
      ]
    }
  };

  const resource = createApiResource<any, SearchParams>({
    name: 'configFileLoader',
    search: ({ pageNumber, pageSize, filters }: SearchParams) => {
      return {
        method: 'GET',
        url: `/BulkLoadHistory?${new URLSearchParams({
          page_number: pageNumber,
          page_size: pageSize,
          ...(filters?.batch_id ? { batchid_filter: filters?.batch_id } : {}),
          ...(filters?.file_name ? { filename_filter: filters?.file_name } : {})
        }).toString()}`,
        params: {},
        responseFormatter: (response) => {
          return response.then((data) => ({
            pageCount: data.page_count,
            recordCount: data.record_count,
            // @ts-ignore
            data: data?.search_response?.map(({ session_quota, ...item }) => ({
              ...item,
              session_quota: JSON.stringify(session_quota)
            }))
          }));
        }
      };
    }
  });
  return (
    <Box sx={{ padding: 2, width: '100%', height: '100%' }}>
      <DataList apiResource={resource} {...DataListProps}></DataList>
      <ConfigurationBulkUpload
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Box>
  );
}
