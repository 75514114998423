import {
  createApiResource,
  SearchParams
} from '../../../api/createApiResource';
import { SchemaPage } from '../../../sharedComponents/schemaPage';
import TenantParametersIconPath from '../../../icons/tenant-information.svg';
import { TenantParameters } from './tenantParameters.interfaces';

const resource = createApiResource<TenantParameters, SearchParams>({
  name: 'tenantParameters',
  search: ({ pageNumber, pageSize }: SearchParams) => {
    return {
      method: 'GET',
      url: `/TenantConfigurationRole?${new URLSearchParams({
        page_number: pageNumber,
        page_size: pageSize
      }).toString()}`,
      params: {},
      responseFormatter: (response) => {
        return response.then((data) => ({
          pageCount: data.page_count,
          recordCount: data.record_count,
          data: data?.search_response
        }));
      }
    };
  },
  create: () => {
    return {
      url: '/TenantConfigurationRole',
      method: 'POST'
    };
  },
  update: () => {
    return {
      url: '/TenantConfigurationRole',
      method: 'PUT'
    };
  },
  remove: (data) => {
    return {
      url: `/TenantConfigurationRole/${encodeURIComponent(
        data.tenant_environment_id
      )}`
    };
  },
  entitySpecUpdate: () => ({
    method: 'GET',
    url: '/EntitySpec/TenantConfigurationRole'
  })
});

export function TenantParametersList() {
  return (
    <SchemaPage
      apiResource={resource}
      FormProps={{
        uiSchema: {
          'ui:order': [
            'account_id',
            'tenant_environment_id',
            'subtenant_supported',
            'category',
            'description',
            'arcoffline_api_url',
            'tenant_aws_region',
            'bulk_backup_file_validity',
            'bulk_backup_ttl',
            'tz_database_location',
            'bulk_load_history_display_days',
            'mvno_tenant_environment_id',
            'pricing_vector_retained',
            'ignore_network_suspend',
            '*'
          ]
        }
      }}
      DataListProps={{
        glyphPath: TenantParametersIconPath,
        searchTermOptions: [],
        pageTitle: 'Tenant Parameters',
        DataGridProps: {
          getRowId: (row) => row.tenant_environment_id,
          columns: [
            {
              field: 'account_id',
              headerName: 'Account Id',
              flex: 1.5
            },
            {
              field: 'tenant_environment_id',
              headerName: 'Tenant Environment Id',
              flex: 1
            },
            {
              field: 'category',
              headerName: 'Category',
              flex: 1
            },
            {
              field: 'description',
              headerName: 'Description',
              flex: 1
            },
            {
              field: 'arcoffline_api_url',
              headerName: 'Arc Offline Api Url',
              flex: 1
            },
            {
              field: 'tenant_aws_region',
              headerName: 'Tenant Aws Region',
              flex: 1
            },
            {
              field: 'bulk_backup_file_validity',
              headerName: 'Bulk Backup File Validity',
              flex: 0.5
            },
            {
              field: 'bulk_backup_ttl',
              headerName: 'Bulk Backup TTL',
              flex: 0.5
            },
            {
              field: 'tz_database_location',
              headerName: 'Database Location',
              flex: 1
            },
            {
              field: 'subtenant_supported',
              headerName: 'Subtenant Supported',
              flex: 1,
              valueGetter: (value) => (value ? 'Yes' : 'No')
            }
          ]
        }
      }}
    />
  );
}
