import { writeToSessionStorage } from './session.storage';

export const AUTHENTICATION_REDIRECT_KEY = 'strac-authentication-redirect'
export const AUTHENTICATION_REDIRECT_MESSAGE = 'strac-authentication-redirect-message';

/**
 * Take user to login page
 * @param preventRedirect - don't include the return path
 */
export function sendToLogin(
  loginUrl: string,
  message?: string,
  preventRedirect: boolean = false
) {
  if (!preventRedirect) {
    writeToSessionStorage(
      AUTHENTICATION_REDIRECT_KEY,
      `${window.location.pathname}${window.location.search}`
    );
    writeToSessionStorage(
      AUTHENTICATION_REDIRECT_MESSAGE,
      message || ''
    );
  }
  window.location.assign(loginUrl);
}
