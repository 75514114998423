import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { SvgIcon, SvgIconProps } from './Icon';
const MainIconContainer = styled(Box)`
  line-height: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const NoRowsContainer = styled(Box)`
  min-height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
`;

interface TableEmptyStateProps {
  glyphPath: SvgIconProps['src'];
  title?: string;
  description?: React.ReactNode;
  hideDescription?: boolean;
}
export function TableEmptyState({
  glyphPath,
  title,
  description,
  hideDescription
}: TableEmptyStateProps) {
  return (
    <NoRowsContainer data-cy="tableEmptyState">
      <MainIconContainer>
        <SvgIcon
          src={glyphPath}
          sx={{
            fontSize: '4rem',
            height: '64px',
            width: '64px'
          }}
        />
      </MainIconContainer>
      <Typography data-cy="tableEmptyState-title" variant="h5">
        {title || 'No Data Found'}
      </Typography>
      <Typography data-cy="tableEmptyState-description" variant="body1">
        {hideDescription
          ? ''
          : description || 'Adjust your filters to see available data.'}
      </Typography>
    </NoRowsContainer>
  );
}
