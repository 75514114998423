import $RefParser from '@apidevtools/json-schema-ref-parser';
import { logOut } from '../App';
import { AuthenticationMessages } from '../constants/common.constants';

export interface SearchParams<
  Filters extends Record<string, any> = Record<string, any>
> {
  pageNumber: string;
  pageSize: string;
  filters?: Filters;
}

interface ApiResourceOption extends Partial<RequestInit> {
  url: string;
  responseFormatter?: (data: Promise<any>) => any;
}

const UNAUTHORIZED_ERROR_STATUS_CODE = 401;

function getIDPTokenValidated(responseData: any, type?: string) {
  if(responseData.status === UNAUTHORIZED_ERROR_STATUS_CODE){
    logOut(AuthenticationMessages.invalidAuthenticationLogOut);
    return null;
  }
    return type === 'update' || type=== 'remove' ? responseData : responseData.json();

}

export interface CreateApiResourceOptions<
  Entity,
  FilterTypes extends Record<string, any>
> {
  name: string;
  supportsRemove?: boolean;
  create?: (data: Entity) => ApiResourceOption;
  search?: (data: SearchParams<FilterTypes>) => ApiResourceOption;
  update?: (data: any) => ApiResourceOption;
  entitySpecCreate?: $RefParser.JSONSchema | (() => ApiResourceOption);
  entitySpecRetrieve?: $RefParser.JSONSchema | (() => ApiResourceOption);
  entitySpecSearch?: $RefParser.JSONSchema | (() => ApiResourceOption);
  entitySpecUpdate?: $RefParser.JSONSchema | (() => ApiResourceOption);
  entitySpec?: () => ApiResourceOption;
  retrieve?: (data: any) => ApiResourceOption;
  remove?: (data: Entity) => ApiResourceOption;
}

export interface ApiResource {
  name: string;
  create: (x: any) => Promise<any>;
  remove?: (x: any) => Promise<any>;
  retrieve: (x: any) => Promise<any>;
  search: (x: any) => Promise<any>;
  update: (x: any) => Promise<any>;
  entitySpecCreate:
    | $RefParser.JSONSchema
    | (() => Promise<$RefParser.JSONSchema>);
  entitySpecRetrieve:
    | $RefParser.JSONSchema
    | (() => Promise<$RefParser.JSONSchema>);
  entitySpecSearch:
    | $RefParser.JSONSchema
    | (() => Promise<$RefParser.JSONSchema>);
  entitySpecUpdate:
    | $RefParser.JSONSchema
    | (() => Promise<$RefParser.JSONSchema>);
}

export function fetchCommon(): Partial<RequestInit> {
  return {
    headers: {
      'Content-Type': 'application/json',
      bu: getBusinessUnit(),
      Authorization: `Bearer ${
        sessionStorage.getItem('starc-sessionId')
          ? JSON.parse(sessionStorage.getItem('starc-sessionId') as string)
          : ''
      }`
    }
  };
}

function getApiBaseUrl() {
  return  sessionStorage.getItem('starc-api-base-url') && JSON.parse(sessionStorage.getItem('starc-api-base-url') as string);
}

function getBusinessUnit(){
  return  sessionStorage.getItem('starc-api-bu') && JSON.parse(sessionStorage.getItem('starc-api-bu') as string);

}

export function createApiResource<Entity, SearchParam>({
  name,
  supportsRemove,
  create,
  search,
  update,
  entitySpecCreate,
  entitySpecRetrieve,
  entitySpecSearch,
  entitySpecUpdate,
  entitySpec,
  retrieve,
  remove
}: CreateApiResourceOptions<Entity, SearchParam>): ApiResource {
  const createResource = (data: any) => {
    if (create) {
      const { url, responseFormatter, ...requestInfo } = create(data);
      const config: RequestInit = {
        ...fetchCommon(),
        ...(requestInfo.method !== 'GET' ? { body: JSON.stringify(data) } : {}),
        method: 'POST',
        ...requestInfo
      };
      return fetch(`${getApiBaseUrl()}${url}`, config).then((response) =>
        responseFormatter
          ? responseFormatter(getIDPTokenValidated(response))
          : getIDPTokenValidated(response)
      );
    }
    return new Promise((_resolve, reject) => reject());
  };

  const searchResource = (data: any) => {
    if (search) {
      const { url, responseFormatter, ...requestInfo } = search(data);
      const config: RequestInit = {
        ...fetchCommon(),
        ...(requestInfo.method !== 'GET' ? { body: JSON.stringify(data) } : {}),
        method: 'POST',
        ...requestInfo
      };
      return fetch(`${getApiBaseUrl()}${url}`, config).then((response) => {
        return responseFormatter
          ? responseFormatter(getIDPTokenValidated(response))
          : getIDPTokenValidated(response);
      });
    }
    return new Promise((_resolve, reject) => reject());
  };

  const updateResource = (data: any) => {
    if (update) {
      const { url, responseFormatter, ...requestInfo } = update(data);
      const config: RequestInit = {
        ...fetchCommon(),
        ...(requestInfo.method !== 'GET' ? { body: JSON.stringify(data) } : {}),
        method: 'POST',
        ...requestInfo
      };
      return fetch(`${getApiBaseUrl()}${url}`, config).then((response) => {
        return getIDPTokenValidated(response, 'update');
      });
    }
    return new Promise((_resolve, reject) => reject());
  };

  const retrieveResource = (data: any) => {
    if (retrieve) {
      const { url, responseFormatter, ...requestInfo } = retrieve(data);
      const config: RequestInit = {
        ...fetchCommon(),
        method: 'GET',
        ...requestInfo
      };
      return fetch(`${getApiBaseUrl()}${url}`, config).then((response) =>
        responseFormatter
          ? responseFormatter(getIDPTokenValidated(response))
          : getIDPTokenValidated(response)
      );
    }
    return new Promise((_resolve, reject) => reject());
  };
  const createSpecResource =
    typeof entitySpecCreate === 'object'
      ? entitySpecCreate
      : () => {
          if (entitySpecCreate) {
            const { url, responseFormatter, ...requestInfo } =
              entitySpecCreate();
            const config: RequestInit = {
              method: 'GET',
              ...requestInfo
            };
            return fetch(`${getApiBaseUrl()}${url}`, config).then((response) =>
              responseFormatter
                ? responseFormatter(getIDPTokenValidated(response))
                : getIDPTokenValidated(response)
            );
          }
          return new Promise((_resolve, reject) => reject());
        };
  const updateSpecResource =
    typeof entitySpecUpdate === 'object'
      ? entitySpecUpdate
      : () => {
          if (entitySpecUpdate) {
            const { url, responseFormatter, ...requestInfo } =
              entitySpecUpdate();
            const config: RequestInit = {
              ...fetchCommon(),
              method: 'GET',
              ...requestInfo
            };
            return fetch(`${getApiBaseUrl()}${url}`, config).then((response) =>
              responseFormatter
                ? responseFormatter(getIDPTokenValidated(response))
                : getIDPTokenValidated(response)
            );
          }
          return new Promise((_resolve, reject) => reject());
        };
  const searchSpecResource =
    typeof entitySpecSearch === 'object'
      ? entitySpecSearch
      : () => {
          if (entitySpecSearch) {
            const { url, responseFormatter, ...requestInfo } =
              entitySpecSearch();

            const config: RequestInit = {
              ...fetchCommon(),
              method: 'GET',
              ...requestInfo
            };
            return fetch(`${getApiBaseUrl()}${url}`, config).then((response) =>
              responseFormatter
                ? responseFormatter(getIDPTokenValidated(response))
                : getIDPTokenValidated(response)
            );
          }
          return new Promise((_resolve, reject) => reject());
        };
  const retrieveSpecResource =
    typeof entitySpecRetrieve === 'object'
      ? entitySpecRetrieve
      : () => {
          if (entitySpecRetrieve) {
            const { url, responseFormatter, ...requestInfo } =
              entitySpecRetrieve();
            const config: RequestInit = {
              ...fetchCommon(),
              method: 'GET',
              ...requestInfo
            };
            return fetch(`${getApiBaseUrl()}${url}`, config).then((response) =>
              responseFormatter
                ? responseFormatter(getIDPTokenValidated(response))
                : getIDPTokenValidated(response)
            );
          }
          return new Promise((_resolve, reject) => reject());
        };

  const result: ApiResource = {
    name,
    create: createResource,
    entitySpecCreate: createSpecResource,
    entitySpecRetrieve: retrieveSpecResource,
    entitySpecSearch: searchSpecResource,
    entitySpecUpdate: updateSpecResource,
    retrieve: retrieveResource,
    search: searchResource,
    update: updateResource
  };

  if (supportsRemove || remove) {
    result.remove = (data: any) => {
      const { url, ...removeRequestInfo } = remove ? remove(data) : { url: '' };
      const config: RequestInit = {
        ...fetchCommon(),
        method: 'DELETE',
        ...removeRequestInfo
      };
      return fetch(`${getApiBaseUrl()}${url}`, config).then((response) => {
        return getIDPTokenValidated(response, 'remove');
      }
      );
    };
  }

  return result;
}

interface StacApiResourceOption extends Pick<RequestInit, 'method' | 'body'> {
  url?: string;
  data? :  Object;
  excludeContentTypeHeader?: boolean;
}

export function starcConfigResource(configObject: StacApiResourceOption) {
  const { url, method, data, body, excludeContentTypeHeader } = configObject;
  let config;
  if (excludeContentTypeHeader) {
    const commonHeaders = fetchCommon();
    //@ts-ignore
    delete commonHeaders.headers['Content-Type'];
    config = {
      ...commonHeaders,
      ...(method !== 'GET' ? { body: body } : {}),
      method: method
    };
  } else {
    config = {
      ...fetchCommon(),
      ...(method !== 'GET' ? { body: JSON.stringify(data) } : {}),
      method: method
    };
  }
  return fetch(`${getApiBaseUrl()}${url}`, config).then((response) =>
    getIDPTokenValidated(response)
  );
}