import { createApiResource } from '../../../api/createApiResource';
import { SchemaPage } from '../../../sharedComponents/schemaPage';
import PriceMappingIconPath from '../../../icons/price-mapping.svg';
import { SearchParams } from '../../../sharedComponents/dataList/dataList.interfaces';
import { EventEst } from './eventEST.interfaces';

const resource = createApiResource<EventEst, SearchParams>({
  name: 'event',
  search: ({ pageNumber, pageSize, filters }: SearchParams) => {
    return {
      method: 'GET',
      url: `/EventEST?${new URLSearchParams({
        page_number: pageNumber,
        page_size: pageSize,
        ...(filters?.derivation_key
          ? { derivation_key_filter: filters.derivation_key }
          : {}),
        ...(filters?.subtenant ? { subtenant_filter: filters?.subtenant } : {})
      }).toString()}`,
      params: {},
      responseFormatter: (response) => {
        return response.then((data) => ({
          pageCount: data.page_count,
          recordCount: data.record_count,
          data: data?.search_response?.map(
            ({ session_quota, ...item }: EventEst) => ({
              ...item,
              session_quota: session_quota
                ? JSON.stringify(session_quota)
                : undefined
            })
          )
        }));
      }
    };
  },
  create: ({ session_quota, ...data }) => {
    return {
      url: '/EventEST',
      method: 'POST',
      body: JSON.stringify({
        ...data,
        session_quota: session_quota && JSON.parse(session_quota)
      })
    };
  },
  update: ({ session_quota, ...data }) => {
    return {
      url: '/EventEST',
      method: 'PUT',
      body: JSON.stringify({
        ...data,
        session_quota: session_quota && JSON.parse(session_quota)
      })
    };
  },
  remove: (data) => {
    if (data.subtenant) {
      return {
        url: `/EventEST/${encodeURIComponent(
          data.derivation_key
        )}/${encodeURIComponent(data.subtenant)}`
      };
    }
    return {
      url: `/EventEST/${encodeURIComponent(data.derivation_key)}`
    };
  },
  entitySpecUpdate: () => ({
    method: 'GET',
    url: '/EntitySpec/EventEST'
  })
});

const eventListSchema = {
  Schema: {
    properties: {
      derivation_key: {
        readOnly: true
      },
      subtenant: {
        readOnly: true
      }
    }
  }
};

export function EventESTList() {
  return (
    <SchemaPage
      apiResource={resource}
      entitySpecOverrides={eventListSchema}
      FormProps={{
        uiSchema: {
          'ui:order': [
            'derivation_key',
            'call_category',
            'session_quota',
            '*',
            'diameter_response_param',
            'meta_data',
            'additional_attributes'
          ],
          session_quota: {
            'ui:widget': 'textarea',
            'ui:options': {
              rows: 3
            }
          }
        }
      }}
      DataListProps={{
        glyphPath: PriceMappingIconPath,
        searchTermOptions: [
          { label: 'Derivation Key', value: 'derivation_key' },
          { label: 'Subtenant Id', value: 'subtenant' }
        ],
        pageTitle: 'Event Price Mapping',
        DataGridProps: {
          getRowId: (row) => row.derivation_key,
          columns: [
            {
              field: 'derivation_key',
              headerName: 'Derivation Key',
              flex: 1.5
            },
            {
              field: 'session_quota',
              headerName: 'Session Quota',
              renderCell: ({ row: item }) => JSON.stringify(item.session_quota),
              flex: 1
            },
            {
              field: 'diameter_response_param',
              headerName: 'Diameter Parameters',
              renderCell: ({ row: item }) =>
                JSON.stringify(item.diameter_response_param),
              flex: 1
            },
            {
              field: 'additional_attributes',
              headerName: 'Additional Attributes',
              renderCell: ({ row: item }) =>
                JSON.stringify(item.additional_attributes),
              flex: 1
            },
            {
              field: 'category',
              headerName: 'Category',
              flex: 1
            },
            {
              field: 'subtenant',
              headerName: 'Subtenant',
              flex: 1
            }
          ]
        }
      }}
    />
  );
}
