import { useEffect, useState } from 'react';
import { RjsForm, RjsFormProps } from '../rjsf/rjsForm';
import { Box, IconButton } from '@mui/material';
import { ApiResource } from '../../api/createApiResource';
import { DataList, DataListProps } from '../dataList';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemButton
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getListDataQueryKey } from '../dataList/useListDataQuery';
import { MenuButton } from '../menuButton/MenuButton';
import { useNotification } from '../../hooks/notify.hook';
import { EntitySpecOverride } from './schemaPageInterfaces';
import { sendToLogin } from '../Login/auth.helpers';
import { LOGIN_PATH } from '../../AppRouter';
import { readToSessionStorage } from '../Login/session.storage';
import Typography from '@mui/material/Typography';
import CloseIconPath from '../../icons/close_large.svg';
import EllipsisIconPath from '../../icons/more-vert.svg';
import { SvgIcon } from '../Icon';

export interface SchemaPageProps {
  apiResource: ApiResource;
  entitySpecOverrides?: EntitySpecOverride;
  DataListProps: Omit<DataListProps, 'apiResource'>;
  FormProps: Omit<RjsFormProps, 'apiResource'>;
}

export function SchemaPage({
  apiResource,
  entitySpecOverrides,
  DataListProps: {
    pageTitle,
    DataGridProps: { columns, ...DataGridProps },
    ...DataListProps
  },
  FormProps
}: SchemaPageProps) {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const { success, error } = useNotification();
  const [selectedItemForRemove, setselectedItemForRemove] = useState<any>();
  const [selectedItem, setSelectedItem] = useState<any>();
  const queryClient = useQueryClient();
  const save = useMutation((data) => {
    return selectedItem
      ? apiResource.update(data).then((response) => {
          if (!response?.ok) {
            error('Invalid JSON specified, please check inputs');
          } else {
            success('Updated successfully.');
            onDetailsDialogClosed();
          }
        })
      : apiResource.create(data).then((response) => {
          if (response?.message) {
            error(response?.message);
          } else {
            success('Created successfully.');
            onDetailsDialogClosed();
          }
        });
  });

  const remove = useMutation((data) => {
    return apiResource.remove?.(data) || Promise.resolve();
  });

  useEffect(() => {
    if (!readToSessionStorage('starc-sessionId')) {
      sendToLogin(LOGIN_PATH);
    }
  }, []);

  function onDetailsDialogClosed() {
    setIsDetailsModalOpen(false);
    setSelectedItem(undefined);
  }

  return (
    <>
      <Box sx={{ padding: 2, width: '100%', height: '100%' }}>
        <DataList
          pageTitle={pageTitle}
          apiResource={apiResource}
          onCreateButtonClick={() => setIsDetailsModalOpen(true)}
          DataGridProps={{
            columns: [
              ...columns,
              {
                field: '__more_menu__',
                headerName: '',
                resizable: false,
                sortable: false,
                renderCell: ({ row }) => (
                  <MenuButton
                    id="moreActions"
                    menuContent={({ close }) => (
                      <Box>
                        <List>
                          <ListItem
                            disablePadding
                            onClick={() => {
                              setSelectedItem(row);
                              setIsDetailsModalOpen(true);
                              close();
                            }}
                          >
                            <ListItemButton data-cy="moreActions-edit">
                              <ListItemText>Edit</ListItemText>
                            </ListItemButton>
                          </ListItem>
                          <ListItem
                            disablePadding
                            onClick={() => {
                              setselectedItemForRemove(row);
                              close();
                            }}
                          >
                            <ListItemButton data-cy="moreActions-remove">
                              <ListItemText>Remove</ListItemText>
                            </ListItemButton>
                          </ListItem>
                        </List>
                      </Box>
                    )}
                  >
                    <SvgIcon
                      src={EllipsisIconPath}
                      sx={{ fontSize: '14px', height: '14px', width: '14px' }}
                    />
                  </MenuButton>
                )
              }
            ],
            ...DataGridProps
          }}
          {...DataListProps}
        ></DataList>
      </Box>

      <Dialog
        PaperProps={{ sx: { borderRadius: 3 } }}
        scroll="paper"
        open={isDetailsModalOpen}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Box>
              {selectedItem ? `Edit ${pageTitle}` : `Create ${pageTitle}`}
            </Box>
            <IconButton
              onClick={onDetailsDialogClosed}
              data-cy="dialog-closeIcon"
            >
              <SvgIcon
                src={CloseIconPath}
                sx={{ fontSize: '14px', height: '14px', width: '14px' }}
              />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <RjsForm
            entitySpecOverrides={entitySpecOverrides}
            apiResource={apiResource}
            {...FormProps}
            id="detailsForm"
            formData={selectedItem}
            onSubmit={({ formData }) => {
              save.mutate(formData, {
                onSuccess: () => {
                  queryClient.invalidateQueries(
                    getListDataQueryKey(apiResource.name)
                  );
                }
              });
            }}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-between' }}>
          <Box
            fontSize={(theme) => theme.spacing(1.75)}
            color={({ palette }) => palette.grey[700]}
            justifyContent="flex-start"
          >
            <Typography display="inline-block" color="error">
              *
            </Typography>{' '}
            Indicates a required field
          </Box>
          <Box>
            <Button
              sx={{ mr: 1 }}
              onClick={onDetailsDialogClosed}
              color="inherit"
              data-cy="createDialogActions-cancel"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              form="detailsForm"
              data-cy="createDialogActions-save"
            >
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog
        PaperProps={{ sx: { borderRadius: 3 } }}
        scroll="paper"
        open={!!selectedItemForRemove}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Box> Remove {pageTitle}</Box>
            <IconButton
              onClick={() => setselectedItemForRemove(null)}
              data-cy="confirmationDialog-title"
            >
              <SvgIcon
                src={CloseIconPath}
                sx={{ fontSize: '14px', height: '14px', width: '14px' }}
              />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mt={1}>
            This will permanently remove {pageTitle} item from the list. Once
            removed it cannot be recovered. Are you sure you want to remove it?
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            data-cy="confirmationDialogAction-cancel"
            color="inherit"
            variant="text"
            onClick={() => setselectedItemForRemove(null)}
          >
            Cancel
          </Button>
          <Button
            type="button"
            color="error"
            data-cy="confirmationDialogAction-confirm"
            variant="contained"
            onClick={() => {
              remove.mutate(selectedItemForRemove, {
                onSuccess: () => {
                  success('Removed Successfully.');
                  setselectedItemForRemove(null);
                  queryClient.invalidateQueries(
                    getListDataQueryKey(apiResource.name)
                  );
                }
              });
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
