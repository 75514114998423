import { createApiResource, SearchParams } from '../../api/createApiResource';
import { SchemaPage } from '../../sharedComponents/schemaPage';
import PolicyConfigIconPath from '../../icons/policy-config.svg';
import { PolicyProfile } from './policyProfile.interfaces';

const resource = createApiResource<PolicyProfile, SearchParams>({
  name: 'policyProfile',
  search: ({ pageNumber, pageSize, filters }: SearchParams) => {
    return {
      method: 'GET',
      url: `/PolicyProfileMapping?${new URLSearchParams({
        page_number: pageNumber,
        page_size: pageSize,
        ...(filters?.plan_id ? { plan_id_filter: filters?.plan_id } : {})
      }).toString()}`,
      params: {},
      responseFormatter: (response) => {
        return response.then((data) => ({
          pageCount: data.page_count,
          recordCount: data.record_count,
          data: data?.search_response
        }));
      }
    };
  },
  create: () => {
    return {
      url: '/PolicyProfileMapping',
      method: 'POST'
    };
  },
  update: () => {
    return {
      url: '/PolicyProfileMapping',
      method: 'PUT'
    };
  },
  remove: (data) => {
    return {
      url: `/PolicyProfileMapping/${encodeURIComponent(data.plan_id)}`
    };
  },
  entitySpecUpdate: () => ({
    method: 'GET',
    url: '/EntitySpec/PolicyProfileMapping'
  })
});

const policyProfileSchema = {
  Schema: {
    properties: {
      plan_id: {
        readOnly: true
      }
    }
  }
};

export function PolicyProfileList() {
  return (
    <SchemaPage
      apiResource={resource}
      entitySpecOverrides={policyProfileSchema}
      FormProps={{
        uiSchema: {
          'ui:order': ['plan_id', '*']
        }
      }}
      DataListProps={{
        glyphPath: PolicyConfigIconPath,
        searchTermOptions: [{ label: 'Plan Id', value: 'plan_id' }],
        pageTitle: 'Policy Profile',
        DataGridProps: {
          getRowId: (row) => row.plan_id,
          columns: [
            {
              field: 'plan_id',
              headerName: 'Plan Id',
              flex: 1
            },
            {
              field: 'profile_name',
              headerName: 'Profile',
              flex: 2
            }
          ]
        }
      }}
    />
  );
}
