import { Box, IconProps } from '@mui/material';
import { FC } from 'react';
import { ReactSVG } from 'react-svg';

interface ReactSVGIconProps {
  src: string;
  fill?: string;
  width?: string;
  height?: string;
}

const ReactSVGIcon: FC<ReactSVGIconProps> = ({
  src,
  fill,
  width,
  height,
  ...props
}) => {
  return (
    <ReactSVG fill={fill} src={src} width={width} height={height} {...props} />
  );
};

export interface SvgIconProps extends ReactSVGIconProps, IconProps {}

export function SvgIcon({ src, ...otherProps }: SvgIconProps) {
  return (
    <Box {...otherProps}>
      <ReactSVGIcon src={src} />
    </Box>
  );
}
