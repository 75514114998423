import { authenticateLogin } from '../sharedComponents/Login/loginResource';
import { readToSessionStorage } from '../sharedComponents/Login/session.storage';

export async function getRefreshToken() {
  return authenticateLogin({
    refreshToken:
      sessionStorage.getItem('starc-refresh-token') &&
      (readToSessionStorage('starc-refresh-token') as any),
    name: '',
    password: ''
  });
}
