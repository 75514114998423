import { Typography } from '@mui/material';
import {
  getUiOptions,
  ArrayFieldTitleProps,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema
} from '@rjsf/utils';

/** The `ArrayFieldTitleTemplate` component renders a `TitleFieldTemplate` with an `id` derived from
 * the `idSchema`.
 *
 * @param props - The `ArrayFieldTitleProps` for the component
 */
export default function ArrayFieldTitleTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: ArrayFieldTitleProps<T, S, F>) {
  const { title, uiSchema } = props;
  const options = getUiOptions<T, S, F>(uiSchema);
  const { label: displayLabel = true } = options;
  if (!title || !displayLabel) {
    return null;
  }
  return (
      <Typography fontWeight={(theme) => theme.typography.fontWeightBold} variant="subtitle1">{title}</Typography>
  );
}
