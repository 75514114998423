import {
  createApiResource,
  SearchParams
} from '../../../api/createApiResource';
import { SchemaPage } from '../../../sharedComponents/schemaPage';
import OnlineProcessingIconPath from '../../../icons/online-processing.svg';
import { RedirectUrl } from './refirectUrl.interfaces';

const resource = createApiResource<RedirectUrl, SearchParams>({
  name: 'redirectUrl',
  search: ({ pageNumber, pageSize, filters }: SearchParams) => {
    return {
      method: 'GET',
      url: `/RedirectUrl?${new URLSearchParams({
        page_number: pageNumber,
        page_size: pageSize,
        ...(filters?.product_id
          ? { productid_filter: filters?.product_id }
          : {}),
        ...(filters?.type ? { type_filter: filters?.type } : {})
      }).toString()}`,
      params: {},
      responseFormatter: (response) => {
        return response.then((data) => ({
          pageCount: data.page_count,
          recordCount: data.record_count,
          data: data?.search_response
        }));
      }
    };
  },
  create: () => {
    return {
      url: '/RedirectUrl',
      method: 'POST'
    };
  },
  update: () => {
    return {
      url: '/RedirectUrl',
      method: 'PUT'
    };
  },
  remove: (data) => {
    return {
      url: `/RedirectUrl/${encodeURIComponent(
        data.product_id
      )}|${encodeURIComponent(data.type)}`
    };
  },
  entitySpecUpdate: () => ({
    method: 'GET',
    url: '/EntitySpec/RedirectUrl'
  })
});

const redirectUrlSchema = {
  Schema: {
    properties: {
      product_id: {
        readOnly: true
      },
      type: {
        readOnly: true
      }
    }
  }
};

export function RedirectUrlList() {
  return (
    <SchemaPage
      apiResource={resource}
      entitySpecOverrides={redirectUrlSchema}
      FormProps={{
        uiSchema: {
          'ui:order': ['product_id', 'type', 'fui_config']
        }
      }}
      DataListProps={{
        glyphPath: OnlineProcessingIconPath,
        searchTermOptions: [
          { label: 'Product Id', value: 'product_id' },
          { label: 'Type', value: 'type' }
        ],
        pageTitle: 'Redirect Url',
        DataGridProps: {
          getRowId: (row) => row.product_id,
          columns: [
            {
              field: 'product_id',
              headerName: 'Product Id',
              flex: 1
            },

            {
              field: 'fui_config',
              headerName: 'Fui Config',
              flex: 1
            },
            {
              field: 'type',
              headerName: 'Type',
              flex: 1
            }
          ]
        }
      }}
    />
  );
}
