import { Box, Theme } from '@mui/material';
import { ErrorListProps, RJSFValidationError } from '@rjsf/utils';
import { uniqBy } from 'lodash';
import $RefParser from '@apidevtools/json-schema-ref-parser';
import { SvgIcon } from '../../Icon';
import ExclamationPath from '../../../icons/exclamation-circle.svg';

export function ErrorListTemplate(props: ErrorListProps) {
  const labelErrors = getErrorLabel(props?.schema?.properties, props?.errors);
  var uniquelabelErrors = uniqBy(labelErrors || [], 'label');
  return (
    <Box
      data-cy="formErrorSummary"
      mt={(theme) => theme.spacing(1)}
      padding={(theme) => theme.spacing(1)}
      color={(theme: Theme) => theme.palette.error.main}
      borderRadius={(theme) => theme.spacing(1)}
      bgcolor="canvas.errorLight"
    >
      <Box ml={(theme) => theme.spacing(2)} display="flex">
        <SvgIcon
          src={ExclamationPath}
          sx={({ palette }) => ({
            fill: palette.error.main,
            width: '14px',
            height: '14px'
          })}
        />
        <Box ml={(theme) => theme.spacing(1)}>
          Please correct the following error(s).
        </Box>
      </Box>
      <ul>
        {uniquelabelErrors.map((labelError) => (
          <li key={labelError.label}>
            {labelError.error.message}
          </li>
        ))}
      </ul>
    </Box>
  );
}

export function getErrorLabel(
  schemaProperties: $RefParser.JSONSchema = {},
  errors: RJSFValidationError[]
) {
  return (
    Object.keys(schemaProperties)?.length &&
    errors.map((error: RJSFValidationError) => {
      const schemaTitleObj = Object.entries(schemaProperties).find(
        (schemaArr) => {
          if (schemaArr[1]?.type?.indexOf('array') > -1) {
            return (error?.property || '').includes(schemaArr[0]);
          } else {
            return error?.property === `.${schemaArr[0]}` || error?.params?.missingProperty === schemaArr[0];
          }
        }
      );
      return {
        error,
        label: (schemaTitleObj && schemaTitleObj[1]?.title) || ''
      };
    })
  );
}
