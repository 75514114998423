import {
  FieldTemplateProps,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema,
  getTemplate,
  getUiOptions
} from '@rjsf/utils';
import { Grid } from '@mui/material';
import { isSchemaType } from '../../../utility/schema.helpers';
import { InputLabel } from '../../InputLabel';
import Typography from '@mui/material/Typography';

/** The `FieldTemplate` component is the template used by `SchemaField` to render any field. It renders the field
 * content, (label, description, children, errors and help) inside of a `WrapIfAdditional` component.
 *
 * @param props - The `FieldTemplateProps` for this component
 */
export default function FieldTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: FieldTemplateProps<T, S, F>) {
  const {
    id,
    children,
    classNames,
    disabled,
    hidden,
    label,
    onDropPropertyClick,
    onKeyChange,
    readonly,
    required,
    errors,
    schema,
    uiSchema,
    registry
  } = props;
  const uiOptions = getUiOptions<T, S, F>(uiSchema);
  const WrapIfAdditionalTemplate = getTemplate<
    'WrapIfAdditionalTemplate',
    T,
    S,
    F
  >('WrapIfAdditionalTemplate', registry, uiOptions);

  if (hidden) {
    return <div style={{ display: 'none' }}>{children}</div>;
  }

  return !label ||
    isSchemaType(schema.type, 'object') ||
    isSchemaType(schema.type, 'array') ? (
    children
  ) : (
    <WrapIfAdditionalTemplate
      classNames={classNames}
      disabled={disabled}
      id={id}
      data-cy={id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={required}
      schema={schema}
      uiSchema={uiSchema}
      registry={registry}
    >
      <Grid container gap={1} data-cy={id}>
        <Grid item xs={4}>
          <InputLabel
            //@ts-ignore
            inputInfo={schema && schema['x-toolTipText']}
            id={id}
            sx={({ palette }) => ({
              color:
                Object.keys(errors?.props?.errorSchema || {})?.length > 0
                  ? palette.error.main
                  : palette.text.primary
            })}
          >
            {label} {required && <Typography display="inline-block" color="error">*</Typography>}
          </InputLabel>
        </Grid>
        <Grid item xs={7}>
          {children}
          <Grid
            sx={({ palette }) => ({
              '& .MuiFormHelperText-root': {
                color: palette.error.main
              }
            })}
          >
            {errors}
          </Grid>
        </Grid>
      </Grid>
    </WrapIfAdditionalTemplate>
  );
}
