import { createApiResource } from '../../../api/createApiResource';
import { SchemaPage } from '../../../sharedComponents/schemaPage';
import PriceMappingIconPath from '../../../icons/price-mapping.svg';

const resource = createApiResource<any, any>({
  name: 'voiceEST',
  search: ({
    pageNumber,
    pageSize,
    filters: { derivation_key, subtenant, filter1 }
  }) => {
    return {
      method: 'GET',
      url: `/VoiceEST?${new URLSearchParams({
        page_number: pageNumber,
        page_size: pageSize,
        ...(derivation_key ? { derivation_key_filter: derivation_key } : {}),
        ...(subtenant ? { subtenant_filter: subtenant } : {}),
        ...(filter1 ? { filter1: filter1 } : {})
      }).toString()}`,
      params: {},
      responseFormatter: (response) => {
        return response.then((data) => ({
          pageCount: data.page_count,
          recordCount: data.record_count,
          // @ts-ignore
          data: data?.search_response?.map(({ session_quota, ...item }) => ({
            ...item,
            session_quota: JSON.stringify(session_quota)
          }))
        }));
      }
    };
  },
  create: ({ session_quota, ...data }) => {
    return {
      url: '/VoiceEST',
      method: 'POST',
      body: JSON.stringify({
        ...data,
        session_quota: session_quota && JSON.parse(session_quota)
      })
    };
  },
  update: ({ session_quota, ...data }) => {
    return {
      url: '/VoiceEST',
      method: 'PUT',
      body: JSON.stringify({
        ...data,
        session_quota: session_quota && JSON.parse(session_quota)
      })
    };
  },
  remove: (data) => {
    if (data.subtenant) {
      return {
        url: `/VoiceEST/${encodeURIComponent(data.derivation_key)}/${
          data.subtenant
        }`
      };
    }
    return {
      url: `/VoiceEST/${encodeURIComponent(data.derivation_key)}`
    };
  },
  entitySpecUpdate: () => ({
    method: 'GET',
    url: '/EntitySpec/VoiceEST'
  })
});

const voiceEstSchema = {
  Schema: {
    properties: {
      derivation_key: {
        readOnly: true
      },
      subtenant: {
        readOnly: true
      }
    }
  }
};

export function VoiceEST() {
  return (
    <SchemaPage
      apiResource={resource}
      entitySpecOverrides={voiceEstSchema}
      FormProps={{
        uiSchema: {
          'ui:order': [
            'derivation_key',
            'call_category',
            'session_quota',
            '*',
            'diameter_response_param',
            'meta_data',
            'additional_attributes'
          ],
          session_quota: {
            'ui:widget': 'textarea',
            'ui:options': {
              rows: 3
            }
          }
        }
      }}
      DataListProps={{
        glyphPath: PriceMappingIconPath,
        pageTitle: 'Voice Price Mapping',
        searchTermOptions: [
          { label: 'Derivation Key', value: 'derivation_key' },
          { label: 'Subtenant Id', value: 'subtenant' }
        ],
        DataGridProps: {
          getRowId: (row) => row.derivation_key,
          columns: [
            {
              field: 'derivation_key',
              headerName: 'Derivation Key',
              flex: 2
            },
            {
              field: 'session_quota',
              headerName: 'Session Quota',
              renderCell: ({ row: item }) => JSON.stringify(item.session_quota),
              flex: 1
            },
            {
              field: 'diameter_response_param',
              headerName: 'Diameter Parameters',
              renderCell: ({ row: item }) =>
                JSON.stringify(item.diameter_response_param),
              flex: 1
            },
            {
              field: 'additional_attributes',
              headerName: 'Additional Attributes',
              renderCell: ({ row: item }) =>
                JSON.stringify(item.additional_attributes),
              flex: 1
            },
            {
              field: 'category',
              headerName: 'Category',
              flex: 1
            },
            {
              field: 'subtenant',
              headerName: 'Subtenant',
              flex: 1
            }
          ]
        }
      }}
    />
  );
}
