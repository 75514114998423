import { makeStyles } from '@mui/styles';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Box, Input, ListItemButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { drawerWidth } from './navigationDrawer.constants';
import {
  ThemeOptionsOverides,
  ThemeOverrides
} from '../../theme/theme.interfaces';

export const useStyles = makeStyles((theme: ThemeOptionsOverides) => ({
  root: {
    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      backgroundColor: theme?.palette?.canvas.dark
    },
    '& .MuiButtonBase-root.MuiListItemButton-root': {
      display: 'flex',
      color: theme?.palette?.navigation.fontColor,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textDecoration: 'none',
      padding: 0
    },
    '& .MuiTypography-root.MuiListItemText-primary': {
      fontSize: '0.875rem',
      fontWeight: '400',
      padding: '0.5rem',
      lineHeight: '1.25rem'
    },
    '& .MuiList-root.MuiList-padding': {
      backgroundColor: theme?.palette?.canvas.dark
    },
    '&.MuiListItemButton-root.Mui-selected': {
      color: theme?.palette?.canvas.white,
      background: 'rgba(37, 129, 255, 0.32)',
      borderRadius: '0.375rem'
    },
    '&.MuiListItemText-root:hover': {
      color: theme?.palette?.canvas.white,
      cursor: 'pointer',
      textDecoration: 'none'
    }
  }
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(5)} + 1px)`
  }
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export const ListWrapperStyled = styled(Box)`
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${(props: ThemeOverrides) =>
    props.theme?.palette?.canvas.dark};
  display: flex;
  flex-grow: 1;
  font-size: 0.875rem;
  height: 100%;
  min-height: 400px;
  width: drawerWidth;
  z-index: 1;
`;

export const ListItemButtonStyled = styled(ListItemButton)`
  &:hover {
    color: ${(props: ThemeOverrides) => props.theme?.palette?.canvas.white};
    cursor: pointer;
    text-decoration: none;
  }
  & > span > svg {
    color: ${(props: ThemeOverrides) => props.theme?.palette?.canvas.white};
  }
`;
export const ToolTipLink = styled(Link)`
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  text-decoration: none;
  color: ${(props) => props.theme.palette.text.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  padding: ${(props) => props.theme.spacing(0.5)};
  overflow: hidden;

  &:hover {
    color: ${(props) => props.theme.palette.primary.main} !important;
    background-color: ${(props) => props.theme.palette.grey[200]};
    cursor: pointer;
    text-decoration: none;
  }
`;

export const ToolTipContainer = styled(Box)`
  min-width: 260px;
  max-height: 550px;
  overflow-y: auto;
  overflow-wrap: break-word;
  font-weight: 500;
  position: relative;
  background-color: ${(props: ThemeOverrides) =>
    props.theme?.palette?.canvas.white};
  border-radius: 2px;
  box-shadow: ${(props: ThemeOverrides) => props.theme?.palette?.border.dark} /
    50% 0px 0.125rem 0.3125rem;
  color: ${(props) => props.theme.palette.canvas.dark};
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  justify-content: center;
  min-height: 24px;
`;

export const SearchInput = styled(Input)`
  color: ${(props: ThemeOverrides) => props.theme?.palette?.canvas.white};
  font-size: 0.875rem;
  padding: 0.25rem 2rem;
`;
export const SearchInputContainer = styled(Box)`
  padding: 0.5rem;
  position: relative;
`;

export const SearchIconWrapper = styled(Box)`
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 50%;
`;
