import { Theme } from '@mui/material';
import MUIToolTip, { TooltipProps } from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    tooltipPlacementTop: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5)
    },
    tooltipPlacementBottom: {
      marginTop: theme.spacing(0.5),
      marginBottom:  theme.spacing(0.5)
    }
  };
});

/** Extension of [Material-UI Tooltip](https://material-ui.com/components/tooltips/) -  [Api](https://material-ui.com/api/tooltip/) */
export const ToolTip = ({ children, classes, ...props }: TooltipProps) => {
  const stylesOverrides = useStyles({});
  return (
    <MUIToolTip {...props} classes={{ ...stylesOverrides, ...(classes || {}) }}>
      {children}
    </MUIToolTip>
  );
};
