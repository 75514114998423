import { DataGridPro, LicenseInfo, useGridApiRef } from '@mui/x-data-grid-pro';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { getListDataQueryKey, useListDataQuery } from './useListDataQuery';
import { useStyles } from './dataGrid.styles';
import { TableEmptyState } from '../TableEmptyState';
import { PageLoading } from '../PageLoading';
import { useSearchOptions } from './useSearchOptions';
import { DataListProps } from './dataList.interfaces';
import { SearchBoxWithOptions } from './searchBoxWithOptions';
import SearchIconPath from '../../icons/no-results.svg';
import { styled } from '@mui/material/styles';
import PlusIconPath from '../../icons/plus.svg';
import RefreshIconPath from '../../icons/refresh.svg';
import { SvgIcon } from '../Icon';
import { useQueryClient } from '@tanstack/react-query';
LicenseInfo.setLicenseKey(
  'f1bfce8fcd66d303fb4b6299624ff615Tz02MDY5MSxFPTE3MDg4OTMxNzQwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI='
);

const CreateButtonStyled = styled(Button)`
  border-radius: ${({ theme }) => theme.spacing(2)};
`;

export function DataList({
  apiResource,
  createButtonText,
  DataGridProps,
  searchTermOptions,
  disableCreate,
  filtersBuilder,
  glyphPath,
  onCreateButtonClick,
  allowRefresh,
  initialFiltersRecord,
  pageTitle
}: DataListProps) {
  const {
    page,
    size,
    selectedSearchOption,
    filters,
    searchTerm,
    goToPage,
    changePageSize,
    onFiltersChange,
    onSearchTermChange
  } = useSearchOptions();
  const { data, isFetching } = useListDataQuery({
    apiResource,
    searchOptions: {
      pageNumber: `${+page + 1}`,
      pageSize: size,
      filters: {
        ...filters,
        ...(selectedSearchOption ? { [selectedSearchOption]: searchTerm } : {})
      }
    }
  });
  const queryClient = useQueryClient();
  const classes = useStyles();
  const apiRef = useGridApiRef();
  const selectedFilterRecord = filters || initialFiltersRecord || {};

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          border: '1px solid rgba(224, 224, 224, 1)'
        }}
      >
        <Box
          sx={{
            padding: 1,
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Typography variant="h1" data-cy={`contentHeader-${pageTitle}`}>
            {pageTitle}
          </Typography>
          <Box display="flex">
            {!disableCreate && (
              <CreateButtonStyled
                type="button"
                data-cy="ListPage-headerButtons--createNew"
                variant="contained"
                onClick={onCreateButtonClick}
                startIcon={
                  <SvgIcon
                    src={PlusIconPath}
                    sx={({ palette }) => ({
                      fill: palette.common.white,
                      height: '14px',
                      width: '14px'
                    })}
                  />
                }
              >
                {createButtonText || 'Create New Value'}
              </CreateButtonStyled>
            )}
            {allowRefresh && (
              <Button
                type="button"
                variant="text"
                onClick={() => {
                  queryClient.invalidateQueries(
                    getListDataQueryKey(apiResource.name)
                  );
                }}
                startIcon={
                  <SvgIcon
                    src={RefreshIconPath}
                    sx={({ palette, spacing }) => ({
                      fill: palette.info.main,
                      height: '16px',
                      width: '16px',
                      marginTop: `-${spacing(2)}`,
                      marginLeft: `${spacing(0.875)}`
                    })}
                  />
                }
              ></Button>
            )}
          </Box>
        </Box>
        <Box
          sx={({ palette }) => ({
            display: 'flex',
            justifyContent: 'space-between',
            padding: 1,
            borderBottom: `1px solid ${palette.divider}`,
            background: palette.grey[100]
          })}
        >
          <Box>
            {filtersBuilder?.(selectedFilterRecord, (name, value) => {
              onFiltersChange({
                ...selectedFilterRecord,
                [name]: value
              });
            })}
          </Box>
          {searchTermOptions?.length > 0 && (
            <Box>
              <SearchBoxWithOptions
                options={searchTermOptions}
                initialSearchParamsRecord={{
                  [selectedSearchOption || '1']: searchTerm
                }}
                searchOptionValue={selectedSearchOption}
                onChange={(newSearchRecord) => {
                  const [key, value] = Object.entries(newSearchRecord)[0];
                  onSearchTermChange(key, value);
                }}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          {!data?.data?.length ? (
            <TableEmptyState
              title={
                !searchTerm
                  ? `There are no ${pageTitle.toLowerCase()} to display.`
                  : ''
              }
              description={
                !searchTerm ? (
                  <IconButton
                    data-cy="tableEmptyState-createNew"
                    color="primary"
                    size="small"
                    onClick={onCreateButtonClick}
                  >
                    Start by creating one now.
                  </IconButton>
                ) : (
                  ''
                )
              }
              glyphPath={!searchTerm ? glyphPath : SearchIconPath}
            />
          ) : (
            <DataGridPro
              pagination
              paginationModel={{
                page: +page,
                pageSize: +size
              }}
              rowCount={data?.recordCount}
              disableColumnMenu
              apiRef={apiRef}
              rowHeight={42}
              rows={data?.data || []}
              paginationMode="server"
              onPaginationModelChange={(newModel) => {
                if (newModel.pageSize !== +size) {
                  changePageSize(newModel.pageSize);
                } else if (newModel.page !== +page) {
                  goToPage(newModel.page);
                }
              }}
              disableRowSelectionOnClick
              className={classes.root}
              getRowClassName={(options) =>
                `${
                  apiRef?.current.getRowIndexRelativeToVisibleRows(options.id) %
                  2
                    ? 'Mui-odd'
                    : 'Mui-even'
                }`
              }
              {...DataGridProps}
            />
          )}
        </Box>
      </Box>
      <PageLoading open={isFetching} />
    </>
  );
}
