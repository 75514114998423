import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { DATA_EST_PATH } from './pages/priceMapping/dataEST/dataEST.constants';
import { VoiceEST } from './pages/priceMapping/voiceEST/voiceEST';
import { DataESTList } from './pages/priceMapping/dataEST/dataEST';
import { EventESTList } from './pages/priceMapping/eventEST/eventEST';
import { VOICE_EST_PATH } from './pages/priceMapping/voiceEST/voiceEST.constants';
import { SpecialNumberList } from './pages/specialNumber/specialNumber';
import { SPECIAL_NUMBER_PATH } from './pages/specialNumber/specialNumber.constants';
import { CountryInformationList } from './pages/locationDerivation/countryInformation/countryInformation';
import { COUNTRY_INFORMATION_PATH } from './pages/locationDerivation/countryInformation/countryInformation.constants';
import { OUTBOUND_ACTIONS_PATH } from './pages/onlineProcessingConfig/outboundActions/outboundActions.constants';
import { OutboundActionsList } from './pages/onlineProcessingConfig/outboundActions/outboundActions';
import { ONLINE_PARAMETERS_PATH } from './pages/onlineProcessingConfig/onlineParameters/onlineParameters.constants';
import { OnlineParametersList } from './pages/onlineProcessingConfig/onlineParameters/onlineParameters';
import { BU_ROUTING_PATH } from './pages/onlineProcessingConfig/buRouting/buRouting.constants';
import { BuRoutingList } from './pages/onlineProcessingConfig/buRouting/buRouting';
import { ExternalResultCodeMappingList } from './pages/onlineProcessingConfig/externalResultCodeMapping/externalResultCodeMapping';
import { EXTERNAL_RESULT_CODE_MAPPING_PATH } from './pages/onlineProcessingConfig/externalResultCodeMapping/externalResultCodeMapping.constants';
import { MCC_MNC_LOCATION_PATH } from './pages/locationDerivation/mccMncLocation/mccMncLocation.constants';
import { MccMncLocationList } from './pages/locationDerivation/mccMncLocation/mccMncLocation';
import { EVENT_EST_PATH } from './pages/priceMapping/eventEST/eventEST.constants';
import { REDIRECT_URL_ROUTING_PATH } from './pages/onlineProcessingConfig/redirectUrl/redirectUrl.constants';
import { RedirectUrlList } from './pages/onlineProcessingConfig/redirectUrl/redirectUrl';
import { ENRICHMENT_CONFIGURATION_ROUTING_PATH } from './pages/onlineProcessingConfig/enrichmentConfiguration/enrichmentConfiguration.constants';
import { EnrichmentConfigurationList } from './pages/onlineProcessingConfig/enrichmentConfiguration/enrichmentConfiguration';
import { NetworkLocationList } from './pages/locationDerivation/networkLocation/networkLocation';
import { NETWORK_LOCATION_PATH } from './pages/locationDerivation/networkLocation/networkLocation.constants';
import Home from './pages/locationDerivation/Home/home';
import Login from './sharedComponents/Login/Login';
import { CONFIGURATION_FILE_LOADER_PATH } from './pages/configurationFileLoader/configurationFileLoader.constants';
import ConfigurationFileLoader from './pages/configurationFileLoader/configurationFileLoader';
import { CELL_LOCATION_PATH } from './pages/locationDerivation/cellLocation/cellLocation.constants';
import { CellLocationList } from './pages/locationDerivation/cellLocation/cellLocation';
import { TENANT_PARAMETERS_PATH } from './pages/tenantInformation/tenantParameters/tenantParameters.constants';
import { TenantParametersList } from './pages/tenantInformation/tenantParameters/tenantParameters';
import { PolicyProfileList } from './pages/policyConfig/policyProfile';
import { POLICY_PROFILE_PATH } from './pages/policyConfig/policyProfile.constants';
export const LOGIN_PATH = '/login';
export function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path={LOGIN_PATH} element={<Login />} />
      <Route path="test" element={<div>test</div>} />
      <Route path={EVENT_EST_PATH} element={<EventESTList />} />
      <Route path={VOICE_EST_PATH} element={<VoiceEST />} />
      <Route path={DATA_EST_PATH} element={<DataESTList />} />
      <Route path={SPECIAL_NUMBER_PATH} element={<SpecialNumberList />} />
      <Route path={CELL_LOCATION_PATH} element={<CellLocationList />} />
      <Route path={NETWORK_LOCATION_PATH} element={<NetworkLocationList />} />
      <Route path={MCC_MNC_LOCATION_PATH} element={<MccMncLocationList />} />
      <Route
        path={COUNTRY_INFORMATION_PATH}
        element={<CountryInformationList />}
      />
      <Route path={OUTBOUND_ACTIONS_PATH} element={<OutboundActionsList />} />
      <Route path={ONLINE_PARAMETERS_PATH} element={<OnlineParametersList />} />
      <Route path={BU_ROUTING_PATH} element={<BuRoutingList />} />
      <Route
        path={EXTERNAL_RESULT_CODE_MAPPING_PATH}
        element={<ExternalResultCodeMappingList />}
      />
      <Route path={REDIRECT_URL_ROUTING_PATH} element={<RedirectUrlList />} />
      <Route
        path={ENRICHMENT_CONFIGURATION_ROUTING_PATH}
        element={<EnrichmentConfigurationList />}
      />
      <Route
        path={CONFIGURATION_FILE_LOADER_PATH}
        element={<ConfigurationFileLoader />}
      />
      <Route path={TENANT_PARAMETERS_PATH} element={<TenantParametersList />} />
      <Route path={POLICY_PROFILE_PATH} element={<PolicyProfileList />} />
    </Routes>
  );
}
