import { OptionsObject, SnackbarMessage, useSnackbar } from 'notistack';

export function useNotification() {
  const { enqueueSnackbar } = useSnackbar();

  function notify(message: SnackbarMessage, options: OptionsObject) {
    return enqueueSnackbar(message, options);
  }

  function success(message: SnackbarMessage) {
    return notify(message, {
      variant: 'success'
    });
  }

  function error(message: SnackbarMessage) {
    return notify(message, {
      variant: 'error'
    });
  }

  return {
    error,
    notify,
    success
  };
}
