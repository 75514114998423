import {
  createApiResource,
  SearchParams
} from '../../../api/createApiResource';
import { SchemaPage } from '../../../sharedComponents/schemaPage';
import LocationDerivationIconPath from '../../../icons/location-derivation.svg';
import { CountryInformation } from './countryInformation.interfaces';

const resource = createApiResource<CountryInformation, SearchParams>({
  name: 'countryInformation',
  search: ({ pageNumber, pageSize, filters }: SearchParams) => {
    return {
      method: 'GET',
      url: `/CountryNpanxxMap?${new URLSearchParams({
        page_number: pageNumber,
        page_size: pageSize,
        ...(filters?.number_prefix
          ? { number_prefix_filter: filters.number_prefix }
          : {}),
        ...(filters?.subtenant ? { subtenant_filter: filters?.subtenant } : {})
      }).toString()}`,
      params: {},
      responseFormatter: (response) => {
        return response.then((data) => ({
          pageCount: data.page_count,
          recordCount: data.record_count,
          data: data?.search_response?.map(
            ({ roaming_classification, ...item }: CountryInformation) => ({
              ...item,
              roaming_classification: roaming_classification
                ? JSON.stringify(roaming_classification)
                : undefined
            })
          )
        }));
      }
    };
  },
  create: ({ roaming_classification, ...data }) => {
    return {
      url: '/CountryNpanxxMap',
      method: 'POST',
      body: JSON.stringify({
        ...data,
        roaming_classification: roaming_classification
          ? JSON.parse(roaming_classification)
          : undefined
      })
    };
  },
  update: ({ roaming_classification, ...data }) => {
    return {
      url: '/CountryNpanxxMap',
      method: 'PUT',
      body: JSON.stringify({
        ...data,
        roaming_classification: roaming_classification
          ? JSON.parse(roaming_classification)
          : undefined
      })
    };
  },
  remove: (data) => {
    if (data.subtenant) {
      return {
        url: `/CountryNpanxxMap/${encodeURIComponent(
          data.number_prefix
        )}/${encodeURIComponent(data.subtenant)}`
      };
    }
    return {
      url: `/CountryNpanxxMap/${encodeURIComponent(data.number_prefix)}`
    };
  },
  entitySpecUpdate: () => ({
    method: 'GET',
    url: '/EntitySpec/CountryNpanxxMap'
  })
});

const countryInformationSchema = {
  Schema: {
    properties: {
      number_prefix: {
        readOnly: true
      },
      subtenant: {
        readOnly: true
      }
    }
  }
};

export function CountryInformationList() {
  return (
    <SchemaPage
      apiResource={resource}
      entitySpecOverrides={countryInformationSchema}
      FormProps={{
        uiSchema: {
          'ui:order': [
            'number_prefix',
            'location',
            'rate_zone',
            'time_zone',
            'time_zone_display_name',
            'time_zone_dst_display_name',
            'time_zone_iana_name',
            'pcode',
            'country',
            'location_category',
            'roaming_classification',
            'subtenant',
            'category',
            'address_info'
          ],
          roaming_classification: {
            'ui:widget': 'textarea',
            'ui:options': {
              rows: 3
            }
          }
        }
      }}
      DataListProps={{
        glyphPath: LocationDerivationIconPath,
        searchTermOptions: [
          { label: 'Number Prefix', value: 'number_prefix' },
          { label: 'Subtenant Id', value: 'subtenant' }
        ],
        pageTitle: 'Country Information',
        DataGridProps: {
          getRowId: (row) => +row.number_prefix,
          columns: [
            {
              field: 'number_prefix',
              headerName: 'Number Prefix',
              flex: 2
            },

            {
              field: 'location',
              headerName: 'Location',
              flex: 1
            },
            {
              field: 'country',
              headerName: 'Country',
              flex: 1
            },
            {
              field: 'rate_zone',
              headerName: 'Rate Zone',
              flex: 1
            },
            {
              field: 'time_zone',
              headerName: 'Time Zone',
              flex: 1
            },
            {
              field: 'time_zone_iana_name',
              headerName: 'IANA Time Zone',
              flex: 1
            },
            {
              field: 'location_category',
              headerName: 'Location Category',
              flex: 1
            },
            {
              field: 'subtenant',
              headerName: 'Subtenant',
              flex: 1
            }
          ]
        }
      }}
    />
  );
}
