import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  getTemplate,
  getUiOptions,
  ArrayFieldTemplateProps,
  ArrayFieldTemplateItemType,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema
} from '@rjsf/utils';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import PlusIconPath from '../../../icons/plus.svg';
import { SvgIcon } from '../../Icon';
import { ThemeOverrides } from '../../../theme/theme.interfaces';

const ArrayFieldContainer = styled(Box)`
  border: ${(props) => `1px solid ${props.theme.palette.grey[200]}`};
  padding:  ${(props) => `${props.theme.spacing(0.5)}`} ${(props) => `${props.theme.spacing(2)}`};
  margin: ${(props) => `${props.theme.spacing(2)}`} 0;
  border-radius: ${(props) => `${props.theme.spacing(0.25)}`};
  background-color: ${(props: ThemeOverrides) => props.theme?.palette?.canvas.light};
  & .MuiInputBase-input {
    background-color:  ${({theme}) => `${theme.palette.common.white}`};
  }
  `

/** The `ArrayFieldTemplate` component is the template used to render all items in an array.
 *
 * @param props - The `ArrayFieldTemplateItemType` props for the component
 */
export default function ArrayFieldTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: ArrayFieldTemplateProps<T, S, F>) {
  const {
    canAdd,
    disabled,
    idSchema,
    uiSchema,
    items,
    onAddClick,
    readonly,
    registry,
    required,
    schema,
    title
  } = props;
  const uiOptions = getUiOptions<T, S, F>(uiSchema);
  const ArrayFieldDescriptionTemplate = getTemplate<
    'ArrayFieldDescriptionTemplate',
    T,
    S,
    F
  >('ArrayFieldDescriptionTemplate', registry, uiOptions);
  const ArrayFieldItemTemplate = getTemplate<'ArrayFieldItemTemplate', T, S, F>(
    'ArrayFieldItemTemplate',
    registry,
    uiOptions
  );
  const ArrayFieldTitleTemplate = getTemplate<
    'ArrayFieldTitleTemplate',
    T,
    S,
    F
  >('ArrayFieldTitleTemplate', registry, uiOptions);

  return (
      <Box>
        <ArrayFieldTitleTemplate
          idSchema={idSchema}
          title={uiOptions.title || title}
          schema={schema}
          uiSchema={uiSchema}
          required={required}
          registry={registry}
        />
        <ArrayFieldDescriptionTemplate
          idSchema={idSchema}
          description={uiOptions.description || schema.description}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
        <Grid container={true} key={`array-item-list-${idSchema.$id}`}>
          {items &&
            items.map(
              ({ key, ...itemProps }: ArrayFieldTemplateItemType<T, S, F>) => (
                <ArrayFieldContainer key={`container-${key}`}>
                  <ArrayFieldItemTemplate key={key} {...itemProps} />
                </ArrayFieldContainer>
              )
            )}
          {canAdd && (
            <Grid container>
              <Grid item={true}>
                <Button
                  style={{textTransform: 'capitalize'}}
                  variant="text"
                  disabled={disabled || readonly}
                  onClick={onAddClick}
                  type="button"
                  color="primary"
                  startIcon={<SvgIcon src={PlusIconPath} sx={({ palette }) => ({fill: palette.primary.light, height: '14px', width: '14px'})} />}>
                    Create {schema.title}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
  );
}
