import { Box, styled, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { FONT_SIZE } from '../../constants/sizes';
import HelpIconPath from '../../icons/question-circle.svg';
import { SvgIcon, SvgIconProps } from '../Icon';
import { ToolTip } from './Tooltip';

const TooltipContent = styled(Box)`
  white-space: pre-line;
  max-width: 250px;
  color:  ${({ theme }) => theme.palette.text.primary};
  font-size: ${FONT_SIZE};
  padding: ${({ theme }) => theme.spacing(0.5)};
`;

const ToolTipIcon = styled(Box)`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: fit-content;
`;

const useToolTipStyles = makeStyles((theme: Theme) => ({
  customTooltip: {
    boxShadow: '0 1px 2px rgba(0,1,3,.32)',
    backgroundColor: theme.palette.background.paper
  },
  customArrow: {
    '&::before': {
      border: '1px solid rgba(0,1,3,.1)'
    }
  }
}));

interface InfoToolTipProps {
  info: string | React.ReactNode;
  glyphPath?: SvgIconProps['src'];
}

export function InfoToolTip({
  info,
  glyphPath = HelpIconPath,
  ...otherProps
}: InfoToolTipProps) {
  const classes = useToolTipStyles();
  return (
    <ToolTip
      classes={{
        tooltip: classes.customTooltip,
        arrow: classes.customArrow
      }}
      {...otherProps}
      title={<TooltipContent data-cy="tooltip-content">{info}</TooltipContent>}
      arrow
    >
      <ToolTipIcon className="tooltipIcon">
        <SvgIcon
          sx={(theme) => ({
            width: theme.spacing(2)
          })}
          src={glyphPath}
        />
      </ToolTipIcon>
    </ToolTip>
  );
}
