import {
  Box,
  InputLabel as MuiInputLabel,
  InputLabelProps as MuiInputLabelProps,
  styled
} from '@mui/material';
import { ReactNode } from 'react';
import { InfoToolTip } from '../ToolTip/InfoToolTip';

export interface InputLabelProps extends MuiInputLabelProps {
  inputInfo?: string | ReactNode;
  id? : string;
}

const Label = styled(Box)`
  display: flex;
  height: 100%;
`;

const HelpToolTip = styled(InfoToolTip)`
  padding-left: ${({ theme }) => theme.spacing(1)};
  display: inline;
  & svg {
    vertical-align: text-bottom;
  }
`;

export function InputLabel({
  children,
  htmlFor,
  inputInfo,
  id,
  ...otherProps
}: InputLabelProps) {
  return (
    <Label>
      <MuiInputLabel
        htmlFor={htmlFor}
        style={{
          whiteSpace: 'unset'
        }}
        {...otherProps}
      >
        {children}
      </MuiInputLabel>
      {inputInfo && (
        <HelpToolTip data-cy={`tooltip-${id}`} info={inputInfo} />
      )}
    </Label>
  );
}
