import {
  createApiResource,
  SearchParams
} from '../../../api/createApiResource';
import { SchemaPage } from '../../../sharedComponents/schemaPage';
import OnlineProcessingIconPath from '../../../icons/online-processing.svg';
import { BURouting } from './buRouting.interfaces';

const resource = createApiResource<BURouting, SearchParams>({
  name: 'guidingParameter',
  search: ({ pageNumber, pageSize, filters }: SearchParams) => {
    return {
      method: 'GET',
      url: `/GuidingParameter?${new URLSearchParams({
        page_number: pageNumber,
        page_size: pageSize,
        ...(filters?.guiding_bu_id
          ? { guidingBuID_filter: filters?.guiding_bu_id }
          : {}),
        ...(filters?.type ? { type_filter: filters?.type } : {})
      }).toString()}`,
      params: {},
      responseFormatter: (response) => {
        return response.then((data) => ({
          pageCount: data.page_count,
          recordCount: data.record_count,
          data: data?.search_response
        }));
      }
    };
  },
  create: () => {
    return {
      url: '/GuidingParameter',
      method: 'POST'
    };
  },
  update: () => {
    return {
      url: '/GuidingParameter',
      method: 'PUT'
    };
  },
  remove: (data) => {
    return {
      url: `/GuidingParameter/${encodeURIComponent(data.guid)}`
    };
  },
  entitySpecUpdate: () => ({
    method: 'GET',
    url: '/EntitySpec/GuidingParameter'
  })
});

const buRoutingSchema = {
  Schema: {
    properties: {
      guid: {
        readOnly: true
      },
      guiding_bu_id: {
        readOnly: true
      },
      type: {
        readOnly: true
      }
    }
  }
};

export function BuRoutingList() {
  return (
    <SchemaPage
      apiResource={resource}
      entitySpecOverrides={buRoutingSchema}
      FormProps={{
        uiSchema: {
          'ui:order': [
            'guid',
            'type',
            'guiding_bu_id',
            'start_range',
            'end_range'
          ],
          guid: {
            'ui:disabled': true
          }
        }
      }}
      DataListProps={{
        glyphPath: OnlineProcessingIconPath,
        searchTermOptions: [
          { label: 'Guiding BU Id', value: 'guiding_bu_id' },
          { label: 'Type', value: 'type' }
        ],
        pageTitle: 'BU Routing',
        DataGridProps: {
          getRowId: (row) => row.guid,
          columns: [
            {
              field: 'guid',
              headerName: 'GUID',
              flex: 1
            },

            {
              field: 'guiding_bu_id',
              headerName: 'Guiding BU Id',
              flex: 1
            },
            {
              field: 'type',
              headerName: 'Type',
              flex: 1
            },
            {
              field: 'start_range',
              headerName: 'Start Range',
              flex: 1
            },
            {
              field: 'end_range',
              headerName: 'End Range',
              flex: 1
            }
          ]
        }
      }}
    />
  );
}
