import { Box, styled, Theme } from '@mui/material';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@mui/styles';
import InfoIconPath from '../../icons/info-circle.svg';
import { CloseIcon } from '../Icon/CloseIcon';
import { SvgIcon } from '../../sharedComponents';
import CheckCircleIconPath from '../../icons/check-circle.svg';
import ExclamationIconPath from '../../icons/exclamation-circle.svg';

const IconPadding = styled(Box)`
  margin-right: ${(props) => props.theme.spacing(1)};
  margin-top: -${(props) => props.theme.spacing(0.5)};
`;

const useStyles = makeStyles((theme: Theme) => {
  return {
    iconSize: { fontSize: '16px', height: '14px', width: '14px', fill: 'white' }
  };
});

export function ToastProvider({ children }: PropsWithChildren<{}>) {
  const notistackRef = React.createRef<SnackbarProvider>();
  const classes = useStyles({});

  /**Dismiss Toast */
  function onClickDismiss(key: SnackbarKey) {
    //@ts-ignore
    notistackRef.current.closeSnackbar(key);
  }

  return (
    <SnackbarProvider
      action={(key) => (
        <CloseIcon
          onClick={() => onClickDismiss(key)}
          className={classes.iconSize}
        />
      )}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      iconVariant={{
        success: (
          <IconPadding data-cy="toast-success">
            <SvgIcon className={classes.iconSize} src={CheckCircleIconPath} />
          </IconPadding>
        ),
        error: (
          <IconPadding data-cy="toast-error">
            <SvgIcon src={ExclamationIconPath} className={classes.iconSize} />
          </IconPadding>
        ),
        warning: (
          <IconPadding data-cy="toast-warning">
            <SvgIcon src={ExclamationIconPath} className={classes.iconSize} />
          </IconPadding>
        ),
        info: (
          <IconPadding data-cy="toast-info">
            <SvgIcon src={InfoIconPath} className={classes.iconSize} />
          </IconPadding>
        )
      }}
      ref={notistackRef}
    >
      {children}
    </SnackbarProvider>
  );
}
