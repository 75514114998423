import { createTheme } from '@mui/material/styles';
import { dialogTheme } from './dialog';

export function getPalette() {
  return {
    palette: {
      border: {
        dark: '#4f5b67', //rgb(79, 91, 103)
        medium: '#c1c6cb', //rgb(193, 198, 203)
        error: '#feefec',
        success: '#06C270'
      },
      canvas: {
        dark: '#051C38', // rgb(137,207,240)
        darkGrey: '#4a4a4a', //rgb(29,29,29)
        light: '#f0f3f6', //rgb(240, 243, 246)
        medium: '#c1c6cb', //rgb(193, 198, 203)
        white: '#ffffff', //rgb(255, 255, 255)
        errorLight: '#ff180012'
      },
      navigation: {
        fontColor: '#FFFFFF99',
        rightHeaderBackground: '#035FB9',
        profileIconBackground: '#104a8e'
      },
      status: {
        error: '#e62d19',
        info: '#004eff'
      }
    }
  };
}

export const customTheme = createTheme(
  {
    typography: {
      h1: {
        fontSize: '1.75rem'
      }
    },
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            flexDirection: 'row'
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            position: 'static'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            padding: '4px 16px',
            minWidth: 0,
            textTransform: 'none'
          }
        }
      },
      ...dialogTheme
    },
    palette: {}
  },
  getPalette()
);
