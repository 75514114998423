import PriceMappingIconPath from '../../icons/price-mapping.svg';
import LocationDerivationIconPath from '../../icons/location-derivation.svg';
import SpecialNumberIconPath from '../../icons/special-number.svg';
import OnlineProcessingIconPath from '../../icons/online-processing.svg';
import ConfigurationFileLoaderIconPath from '../../icons/config-file-loader.svg';
import TenantInformationIconPath from '../../icons/tenant-information.svg';
import PolicyConfigIconPath from '../../icons/policy-config.svg';
import { CONFIGURATION_FILE_LOADER_PATH } from './../../pages/configurationFileLoader/configurationFileLoader.constants';
import { DATA_EST_PATH } from '../../pages/priceMapping/dataEST/dataEST.constants';
import { OUTBOUND_ACTIONS_PATH } from './../../pages/onlineProcessingConfig/outboundActions/outboundActions.constants';
import { VOICE_EST_PATH } from '../../pages/priceMapping/voiceEST/voiceEST.constants';
import { SPECIAL_NUMBER_PATH } from '../../pages/specialNumber/specialNumber.constants';
import { ONLINE_PARAMETERS_PATH } from '../../pages/onlineProcessingConfig/onlineParameters/onlineParameters.constants';
import { BU_ROUTING_PATH } from '../../pages/onlineProcessingConfig/buRouting/buRouting.constants';
import { CELL_LOCATION_PATH } from './../../pages/locationDerivation/cellLocation/cellLocation.constants';
import { COUNTRY_INFORMATION_PATH } from './../../pages/locationDerivation/countryInformation/countryInformation.constants';
import { EXTERNAL_RESULT_CODE_MAPPING_PATH } from '../../pages/onlineProcessingConfig/externalResultCodeMapping/externalResultCodeMapping.constants';
import { MCC_MNC_LOCATION_PATH } from '../../pages/locationDerivation/mccMncLocation/mccMncLocation.constants';
import { EVENT_EST_PATH } from '../../pages/priceMapping/eventEST/eventEST.constants';
import { REDIRECT_URL_ROUTING_PATH } from '../../pages/onlineProcessingConfig/redirectUrl/redirectUrl.constants';
import { ENRICHMENT_CONFIGURATION_ROUTING_PATH } from '../../pages/onlineProcessingConfig/enrichmentConfiguration/enrichmentConfiguration.constants';
import { NETWORK_LOCATION_PATH } from '../../pages/locationDerivation/networkLocation/networkLocation.constants';
import { TENANT_PARAMETERS_PATH } from '../../pages/tenantInformation/tenantParameters/tenantParameters.constants';
import { POLICY_PROFILE_PATH } from '../../pages/policyConfig/policyProfile.constants';

export const drawerWidth = 250;
export const leftNavWidth = 44;
export const navigationItems = [
  {
    name: 'Price Mapping',
    glyphPath: PriceMappingIconPath,
    children: [
      {
        name: 'Voice',
        path: VOICE_EST_PATH
      },
      {
        name: 'Event',
        path: EVENT_EST_PATH
      },
      {
        name: 'Data',
        path: DATA_EST_PATH
      }
    ]
  },
  {
    name: 'Special Number',
    glyphPath: SpecialNumberIconPath,
    children: [
      {
        name: 'Special Number',
        path: SPECIAL_NUMBER_PATH
      }
    ]
  },
  {
    name: 'Location Derivation',
    glyphPath: LocationDerivationIconPath,
    children: [
      {
        name: 'Cell Location',
        path: CELL_LOCATION_PATH
      },
      {
        name: 'MCC MNC Location',
        path: MCC_MNC_LOCATION_PATH
      },
      {
        name: 'Network Location (IP)',
        path: NETWORK_LOCATION_PATH
      },
      {
        name: 'Country Information',
        path: COUNTRY_INFORMATION_PATH
      }
    ]
  },
  {
    name: 'Online Processing Config',
    glyphPath: OnlineProcessingIconPath,
    children: [
      {
        name: 'Online Parameters',
        path: ONLINE_PARAMETERS_PATH
      },
      {
        name: 'External Result Code Mapping',
        path: EXTERNAL_RESULT_CODE_MAPPING_PATH
      },
      {
        name: 'Outbound Actions',
        path: OUTBOUND_ACTIONS_PATH
      },
      {
        name: 'Bu Routing',
        path: BU_ROUTING_PATH
      },
      {
        name: 'Enrichment Configuration',
        path: ENRICHMENT_CONFIGURATION_ROUTING_PATH
      },
      {
        name: 'Redirect Url',
        path: REDIRECT_URL_ROUTING_PATH
      }
    ]
  },
  {
    name: 'Policy Config',
    glyphPath: PolicyConfigIconPath,
    children: [
      {
        name: 'Policy Profile',
        path: POLICY_PROFILE_PATH
      }
    ]
  },
  {
    name: 'Configuration File Loader',
    glyphPath: ConfigurationFileLoaderIconPath,
    children: [
      {
        name: 'Configuration File Loader',
        path: CONFIGURATION_FILE_LOADER_PATH
      }
    ]
  },
  {
    name: 'Tenant Information',
    glyphPath: TenantInformationIconPath,
    children: [
      {
        name: 'Tenant Parameters',
        path: TENANT_PARAMETERS_PATH
      }
    ]
  }
];
