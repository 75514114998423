import {
  createApiResource,
  SearchParams
} from '../../../api/createApiResource';
import { SchemaPage } from '../../../sharedComponents/schemaPage';
import OnlineProcessingIconPath from '../../../icons/online-processing.svg';
import { EnrichmentConfiguration } from './enrichmentConfiguration.interfaces';

const resource = createApiResource<EnrichmentConfiguration, SearchParams>({
  name: 'enrichmentConfiguration',
  search: ({ pageNumber, pageSize, filters }: SearchParams) => {
    return {
      method: 'GET',
      url: `/EnrichmentConfiguration?${new URLSearchParams({
        page_number: pageNumber,
        page_size: pageSize,
        ...(filters?.key ? { key_filter: filters?.key } : {}),
        ...(filters?.subtenant ? { subtenant_filter: filters?.subtenant } : {})
      }).toString()}`,
      params: {},
      responseFormatter: (response) => {
        return response.then((data) => ({
          pageCount: data.page_count,
          recordCount: data.record_count,
          data: data?.search_response
        }));
      }
    };
  },
  create: () => {
    return {
      url: '/EnrichmentConfiguration',
      method: 'POST'
    };
  },
  update: () => {
    return {
      url: '/EnrichmentConfiguration',
      method: 'PUT'
    };
  },
  remove: (data) => {
    if (data.subtenant) {
      return {
        url: `/EnrichmentConfiguration/${encodeURIComponent(
          data.key
        )}/${encodeURIComponent(data.subtenant)}`
      };
    }
    return {
      url: `/EnrichmentConfiguration/${encodeURIComponent(data.key)}`
    };
  },
  entitySpecUpdate: () => ({
    method: 'GET',
    url: '/EntitySpec/EnrichmentConfiguration'
  })
});

const enrichmentConfigurationSchema = {
  Schema: {
    properties: {
      key: {
        readOnly: true
      },
      subtenant: {
        readOnly: true
      }
    }
  }
};

export function EnrichmentConfigurationList() {
  return (
    <SchemaPage
      apiResource={resource}
      entitySpecOverrides={enrichmentConfigurationSchema}
      FormProps={{
        uiSchema: {
          'ui:order': ['key', 'value', 'subtenant']
        }
      }}
      DataListProps={{
        glyphPath: OnlineProcessingIconPath,
        searchTermOptions: [
          { label: 'Key', value: 'key' },
          { label: 'Subtenant Id', value: 'subtenant' }
        ],
        pageTitle: 'Enrichment Configuration',
        DataGridProps: {
          getRowId: (row) => row.key,
          columns: [
            {
              field: 'key',
              headerName: 'Key',
              flex: 1
            },

            {
              field: 'value',
              headerName: 'Value',
              flex: 1
            },
            {
              field: 'subtenant',
              headerName: 'Subtenant',
              flex: 1
            }
          ]
        }
      }}
    />
  );
}
