import { ClickAwayListener } from '@mui/material';
import { PropsWithChildren, ReactElement, useState } from 'react';
import { Popper, Button, Paper } from '@mui/material';

interface MenuButtonProps {
  id?: string;
  menuContent: ({ close }: { close: () => void }) => ReactElement;
}

export function MenuButton({
  id,
  children,
  menuContent
}: PropsWithChildren<MenuButtonProps>) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  function close() {
    setAnchorEl(null);
  }

  return (
    <ClickAwayListener onClickAway={close}>
      <div>
        <Button
          sx={(theme) => ({
            height: theme.spacing(4),
            width: theme.spacing(4),
            borderRadius: '50%'
          })}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          data-cy={id}
        >
          {children}
        </Button>
        <Popper open={!!anchorEl} anchorEl={anchorEl}>
          <Paper>{menuContent({ close })}</Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
