const logger = console;

/** Debugging-level information. */
export function debug(message: string, ...objs: any[]) {
  logger.debug(message, objs);
}

/** Non-recoverable errors. */
export function error(message: string, ...objs: any[]) {
  logger.error(message, objs);
}

/** Warnings about future potential issues. */
export function warn(message: string, ...objs: any[]) {
  logger.warn(message, objs);
}

/** Informational messages. */
export function info(message: string, ...objs: any[]) {
  logger.info(message, objs);
}

/** Generic log with unspecified level. */
export function log(message: string, ...objs: any[]) {
  logger.log(message, objs);
}
