import moment, { Moment } from 'moment';

/** Return a date in "m/d/yyyy" or "m/d/yyyy h:mm aa" format. */
export function formatDate(
  date: string,
  includeTime?: boolean,
  /** Display time as UTC without any timezone offset*/
  isUtc?: boolean
) {
  const momentDate = isUtc ? moment.utc(date) : moment(date);
  if (includeTime) {
    return `${momentDate.format('l')} ${momentDate.format('LT')}`;
  }
  return `${momentDate.format('l')}`;
}

/**
 * Adjust a utc datetime string to the billing system's timezone, accounting for browser time.
 * @param utcString - zulu date/time string
 * @param timezone - timezone of the billing system
 */
export function offsetTimezone(utcString: string, timezone: string = '') {
  const dateMinutes = moment(utcString).minutes();
  const timezoneDate = moment().utcOffset(timezone);
  const browserOffset = new Date().getTimezoneOffset();
  // Adjust to billing system and browser timezone.
  const billingDate = moment(utcString).minutes(
    dateMinutes + moment(timezoneDate).utcOffset() + browserOffset
  );
  return billingDate;
}

/**
 * Adjust a date against the billing system's timezone, accounting for browser time.
 * @param dateObject - date/time object
 * @param timezone - timezone of the billing system
 */
export function specifyTimezone(
  dateObject: Date | string,
  timezone: string = ''
) {
  const dateMinutes = moment(dateObject).minutes();
  const timezoneDate = moment().utcOffset(timezone);
  const browserOffset = new Date().getTimezoneOffset();
  // Adjust against billing system and browser timezone.
  const billingDate = moment(dateObject).minutes(
    dateMinutes - moment(timezoneDate).utcOffset() - browserOffset
  );
  return billingDate;
}

/** Return a Moment-compatible value in "yyyy-mm-ddThh:mm:ss.mmmZ" format. */
export function momentToISOString(date: Moment | string | null) {
  return date ? moment(date).toISOString() : null;
}
