import { makeStyles } from '@mui/styles';
import { ThemeOptionsOverides } from '../../theme/theme.interfaces';

export const useStyles = makeStyles((theme: ThemeOptionsOverides) => ({
  root: {
    border: 'none',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none'
    },
    '& .MuiDataGrid-columnSeparator--resizable': {
      display: 'flex'
    },
    '& .MuiDataGrid-row': {
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent'
        }
      },
      '&.Mui-odd': {
        backgroundColor: theme?.palette?.canvas.light,
        '&.Mui-selected': {
          backgroundColor: theme?.palette?.canvas.dark,
          '&:hover': {
            backgroundColor: theme?.palette?.canvas.dark
          }
        }
      }
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold'
    },
    '& .MuiDataGrid-sortIcon': {
      height: '1rem'
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
      '& .MuiIconButton-root': {
        border: 'none',

        '&:hover': {
          border: 'none',
          backgroundColor: 'unset'
        }
      }
    },
    // '& .MuiDataGrid-cell': {
    //   whiteSpace: ({ allowMultiLineRows }) =>
    //     allowMultiLineRows ? 'normal' : 'nowrap',
    //   paddingTop: ({ allowMultiLineRows }) =>
    //     allowMultiLineRows ? theme.spacing(0.5) : undefined,
    //   paddingBottom: ({ allowMultiLineRows }) =>
    //     allowMultiLineRows ? theme.spacing(0.5) : undefined,
    //   alignItems: ({ allowMultiLineRows }) =>
    //     allowMultiLineRows ? 'normal' : undefined,
    //   '& > *': {
    //     maxWidth: '100%'
    //   }
    // },
    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus':
      {
        outline: 'none'
      },
    '& .MuiDataGrid-columnHeader, & .MuiDataGrid-cell': {
      // padding: theme.spacing(0, 1),
      '& *': {
        boxSizing: 'revert'
      }
      // '&.MuiDataGrid-cellCheckbox, &.MuiDataGrid-columnHeaderCheckbox': {
      //   paddingLeft: theme.spacing(0.5)
      // }
    },
    // '& .MuiDataGrid-footerContainer': {
    //   marginTop: theme.spacing(1),
    //   borderTop: `1px solid ${theme.palette.border.main}`
    // },
    // '& .MuiDataGrid-virtualScrollerRenderZone': {
    //   position: ({ disableVirtualization }) =>
    //     disableVirtualization ? 'unset' : undefined
    // },
    // '& .MuiDataGrid-virtualScrollerContent': {
    //   height: ({ disableVirtualization }) =>
    //     disableVirtualization ? 'auto !important' : undefined
    // },
    '& .MuiDataGrid-overlay': {
      position: 'unset !important'
    }
  }
}));
