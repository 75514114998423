import {
  createApiResource,
  SearchParams
} from '../../../api/createApiResource';
import { SchemaPage } from '../../../sharedComponents/schemaPage';
import LocationDerivationIconPath from '../../../icons/location-derivation.svg';
import { MccMncLocation } from './mccMncLocation.interfaces';

const resource = createApiResource<MccMncLocation, SearchParams>({
  name: 'countryInformation',
  search: ({ pageNumber, pageSize, filters }: SearchParams) => {
    return {
      method: 'GET',
      url: `/MccMncLocation?${new URLSearchParams({
        page_number: pageNumber,
        page_size: pageSize,
        ...(filters?.geographic_location
          ? { mccmnc_location_filter: filters.geographic_location }
          : {}),
        ...(filters?.subtenant ? { subtenant_filter: filters?.subtenant } : {})
      }).toString()}`,
      params: {},
      responseFormatter: (response) => {
        return response.then((data) => ({
          pageCount: data.page_count,
          recordCount: data.record_count,
          data: data?.search_response?.map(
            ({ roaming_classification, ...item }: MccMncLocation) => ({
              ...item,
              roaming_classification: roaming_classification
                ? JSON.stringify(roaming_classification)
                : undefined
            })
          )
        }));
      }
    };
  },
  create: ({ roaming_classification, ...data }) => {
    return {
      url: '/MccMncLocation',
      method: 'POST',
      body: JSON.stringify({
        ...data,
        roaming_classification: roaming_classification
          ? JSON.parse(roaming_classification)
          : undefined
      })
    };
  },
  update: ({ roaming_classification, ...data }) => {
    return {
      url: '/MccMncLocation',
      method: 'PUT',
      body: JSON.stringify({
        ...data,
        roaming_classification: roaming_classification
          ? JSON.parse(roaming_classification)
          : undefined
      })
    };
  },
  remove: (data) => {
    if (data.subtenant) {
      return {
        url: `/MccMncLocation/${encodeURIComponent(
          data.geographic_location
        )}/${encodeURIComponent(data.subtenant)}`
      };
    }
    return {
      url: `/MccMncLocation/${encodeURIComponent(data.geographic_location)}`
    };
  },
  entitySpecUpdate: () => ({
    method: 'GET',
    url: '/EntitySpec/MccMncLocation'
  })
});

const mccMncLocationSchema = {
  Schema: {
    properties: {
      geographic_location: {
        readOnly: true
      },
      subtenant: {
        readOnly: true
      }
    }
  }
};

export function MccMncLocationList() {
  return (
    <SchemaPage
      apiResource={resource}
      entitySpecOverrides={mccMncLocationSchema}
      FormProps={{
        uiSchema: {
          'ui:order': [
            'geographic_location',
            'location',
            'country',
            'rate_zone',
            'time_zone',
            'time_zone_display_name',
            'time_zone_dst_display_name',
            'time_zone_iana_name',
            'pcode',
            'location_category',
            'roaming_classification',
            'subtenant',
            'category',
            'address_info'
          ],
          roaming_classification: {
            'ui:widget': 'textarea',
            'ui:options': {
              rows: 3
            }
          }
        }
      }}
      DataListProps={{
        glyphPath: LocationDerivationIconPath,
        searchTermOptions: [
          { label: 'Geographic Location', value: 'geographic_location' },
          { label: 'Subtenant Id', value: 'subtenant' }
        ],
        pageTitle: 'MCC MNC Location',
        DataGridProps: {
          getRowId: (row) => +row.geographic_location,
          columns: [
            {
              field: 'geographic_location',
              headerName: 'Geographic Location',
              flex: 2
            },

            {
              field: 'location',
              headerName: 'Location',
              flex: 1
            },
            {
              field: 'country',
              headerName: 'Country',
              flex: 1
            },
            {
              field: 'rate_zone',
              headerName: 'Rate Zone',
              flex: 1
            },
            {
              field: 'time_zone',
              headerName: 'Time Zone',
              flex: 1
            },
            {
              field: 'time_zone_iana_name',
              headerName: 'IANA Time Zone',
              flex: 1
            },
            {
              field: 'location_category',
              headerName: 'Location Category',
              flex: 1
            },
            {
              field: 'subtenant',
              headerName: 'Subtenant',
              flex: 1
            }
          ]
        }
      }}
    />
  );
}
