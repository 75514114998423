/* eslint-disable no-undef */
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { MouseEventHandler, useState } from 'react';
import SignOut from '../../icons/sign-out.svg';
import ProfileIcon from '../../icons/user-outline.svg';
import { SvgIcon } from '../Icon';
import { ThemeOverrides } from '../../theme/theme.interfaces';

const HeaderProfileButton = styled(Button)`
    height: 64px;
    width: 56px;
    border-radius: 0;
    background-color: ${(props: ThemeOverrides) =>
      props.theme?.palette?.navigation.profileIconBackground};
    }
`;


interface ProfilePopperProps {
  /** log out click handler */
  logOutClick: MouseEventHandler;
}

/**Profile Icon with popover */
export function ProfilePopper({ logOutClick }: ProfilePopperProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e: any) => {
    setAnchorEl(null);
  };

  return (
    <div>
      <HeaderProfileButton
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-cy='header-profile'
      >
        <SvgIcon src={ProfileIcon} width="24px" height="24px" />
      </HeaderProfileButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={logOutClick} data-cy="profile-logout">
          <Box display="flex" alignItems="center">
            <SvgIcon
              src={SignOut}
              sx={(theme) => ({
                fill: theme.palette.common.black[100],
                width: '14px',
                height: '14px',
                fontSize: '0.875rem',
                marginRight: theme.spacing(1)
              })} />
              Logout
          </Box>
        </MenuItem>
      </Menu>
    </div>
  );
}
