import ClockPath from '../../icons/clock-filled.svg';
import EllipsisPath from '../../icons/status-ellipses-filled.svg';
import RemovedIconPath from '../../icons/status-removed-filled.svg';
import CheckPath from '../../icons/task-success.svg';
import { getPalette } from '../../theme';

export const getConfigurationFileLoaderStatuses = () => {
  const themeColors = getPalette();
  return [
    {
      status: 'Success',
      value: {
        glyphPath: CheckPath,
        fill: themeColors.palette.border.success,
        title:'Success'
      }
    },
    {
      status: 'Failed',
      value: {
        glyphPath: RemovedIconPath,
        fill: themeColors.palette.status.error,
        title: 'Failed'
      }
    },
    {
      status: 'Scheduled',
      value: {
        glyphPath: ClockPath,
        fill: themeColors.palette.status.info,
        title: 'Scheduled'
      }
    },
    {
      status: 'InProgress',
      value: {
        glyphPath: EllipsisPath,
        fill:themeColors.palette.status.info,
        title: 'InProgress'
      }
    }
  ];
}

export const CONFIGURATION_FILE_LOADER_PATH = 'configurationfileloader/*';
export const BULK_FILE_TYPE = 'application/json';
export const BULK_FILE_SIZE_LIMIT = 5 * 1024 * 1024;
export const getConfigurationOperationType = () => {
  return [
    {
      label: 'Select',
      value: 'SELECT',
    },
    {
      label: 'ADD',
      value: 'ADD'
    },
    {
      label: 'DELETE',
      value: 'DELETE'
    }
  ];
};
