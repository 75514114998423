import React, { useMemo, useState } from 'react';
import { SearchParams } from './dataList.interfaces';
import { Box, TextField, MenuItem } from '@mui/material';
import { SearchBox } from '../../utility/SearchBox';

export function SearchBoxWithOptions({
  initialSearchParamsRecord,
  placeholder,
  options,
  onChange,
  searchOptionValue: searchoptionvalue
}: {
  initialSearchParamsRecord?: SearchParams;
  placeholder?: string | undefined;
  options: { label: string; value: string }[];
  onChange: (newSearchParamsRecord: SearchParams) => void;
  searchOptionValue?: string | null;
}) {
  const [selectedOptionValue, setSelectedOptionValue] = useState(
    searchoptionvalue || options[0].value
  );
  const [searchValue, setSearchValue] = useState<string>(
    initialSearchParamsRecord?.[selectedOptionValue]
  );

  const onSearchValueChange = (selectedOption: string, searchVal: string) => {
    setSearchValue(searchVal);
    setSelectedOptionValue(selectedOption);
    onChange({ [selectedOption]: searchVal });
  };

  const selectedOption = useMemo(() => {
    return options.find((option) => option.value === selectedOptionValue);
  }, [selectedOptionValue]);

  return (
    <Box display="flex">
      {options.length === 1 ? undefined : (
        <TextField
          size="small"
          data-cy="searchBoxWithOptions-selectType"
          select
          value={selectedOptionValue}
          onChange={(event) => {
            onSearchValueChange(event.target.value, '');
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      <SearchBox
        debounce={500}
        size="small"
        placeholder={
          placeholder || selectedOption?.label
            ? `Search ${selectedOption?.label}...`
            : 'Search...'
        }
        value={searchValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onSearchValueChange(selectedOptionValue, event.target.value);
        }}
      />
    </Box>
  );
}
