import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BASE_SPACING } from '../../constants/sizes';
import { SvgIcon } from '../Icon';
import { ToolTip } from '../ToolTip';

const Item = styled(Box)`
  display: flex;
  align-items: center;
`;
const ToolTipContainer = styled(Box)`
  cursor: pointer;
  width: fit-content;
`;

export interface StatusProps {
  color?: any;
  glyphPath?: string;
  fill?: string;
  keepSpacing?: boolean;
  /** Call back function for on click, will appear as a link */
  onClick?: () => void | undefined;
  size?: number;
  title?: string;
  tooltipMessage?: string;
}

const TitleWrapper = ({
  color,
  glyphPath,
  fill,
  keepSpacing,
  onClick,
  size,
  title
}: Omit<StatusProps, 'tooltipMessage'>) => {
  return (
    <Item>
      {glyphPath ? (
        <SvgIcon
          src={glyphPath}
          height='14px'
          width='14px'
          sx={{
            fontSize: size || undefined,
            paddingRight: 1,
            fill:fill
          }}
        />
      ) : (
        <div
          style={keepSpacing ? { paddingLeft: BASE_SPACING * 4.5 } : undefined}
        />
      )}
      {onClick ? (
        <Button data-cy="status-title--link" onClick={onClick}>
          {title}
        </Button>
      ) : (
        <>{title}</>
      )}
    </Item>
  );
};

export function Status({ tooltipMessage, ...props }: StatusProps) {
  return tooltipMessage ? (
    <ToolTip
      title={tooltipMessage}
      placement="top-start"
      arrow
      disableInteractive
    >
      <ToolTipContainer>
        <TitleWrapper {...props} />
      </ToolTipContainer>
    </ToolTip>
  ) : (
    <TitleWrapper {...props} />
  );
}
