import {
  createApiResource,
  SearchParams
} from '../../../api/createApiResource';
import { SchemaPage } from '../../../sharedComponents/schemaPage';
import OnlineProcessingIconPath from '../../../icons/online-processing.svg';
import { ExternalResultCodeMapping } from './externalResultCodeMapping.interfaces';

const resource = createApiResource<ExternalResultCodeMapping, SearchParams>({
  name: 'diameterResultCodes',
  search: ({ pageNumber, pageSize, filters }: SearchParams) => {
    return {
      method: 'GET',
      url: `/DiameterResultCodes?${new URLSearchParams({
        page_number: pageNumber,
        page_size: pageSize,
        ...(filters?.context_id
          ? { contextid_filter: filters?.context_id }
          : {})
      }).toString()}`,
      params: {},
      responseFormatter: (response) => {
        return response.then((data) => ({
          pageCount: data.page_count,
          recordCount: data.record_count,
          data: data?.search_response
        }));
      }
    };
  },
  create: () => {
    return {
      url: '/DiameterResultCodes',
      method: 'POST'
    };
  },
  update: () => {
    return {
      url: '/DiameterResultCodes',
      method: 'PUT'
    };
  },
  remove: (data) => {
    return {
      url: `/DiameterResultCodes/${encodeURIComponent(data.context_id)}`
    };
  },
  entitySpecUpdate: () => ({
    method: 'GET',
    url: '/EntitySpec/DiameterResultCodes'
  })
});

const externalResultCodeMappingSchema = {
  Schema: {
    properties: {
      context_id: {
        readOnly: true
      }
    }
  }
};

export function ExternalResultCodeMappingList() {
  return (
    <SchemaPage
      apiResource={resource}
      entitySpecOverrides={externalResultCodeMappingSchema}
      FormProps={{
        uiSchema: {
          'ui:order': ['context_id', 'diameter_result_code', '*']
        }
      }}
      DataListProps={{
        glyphPath: OnlineProcessingIconPath,
        searchTermOptions: [{ label: 'Context Id', value: 'context_id' }],
        pageTitle: 'External Result Code Mapping',
        DataGridProps: {
          getRowId: (row) => row.context_id,
          columns: [
            {
              field: 'context_id',
              headerName: 'Context Id',
              flex: 1
            },
            {
              field: 'diameter_result_code',
              headerName: 'Diameter Result Code',
              flex: 1
            },
            {
              field: 'category',
              headerName: 'Category',
              flex: 1
            }
          ]
        }
      }}
    />
  );
}
