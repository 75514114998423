import { useLocation, useNavigate, generatePath } from 'react-router-dom';

const queryParamsName = {
  search: 'search',
  pageSize: 'size',
  pageNumber: 'page',
  filters: 'filter',
  searchBy: 'searchby'
};

export function useSearchOptions() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const size = +(queryParams.get(queryParamsName.pageSize) || 25);
  const page = +(queryParams.get(queryParamsName.pageNumber) || 0);
  const filtersQueryParam = queryParams.get(queryParamsName.filters);
  const searchTerm = queryParams.get(queryParamsName.search);
  const filters = filtersQueryParam && JSON.parse(filtersQueryParam);
  const selectedSearchOption = queryParams.get(queryParamsName.searchBy);

  return {
    page: `${page}`,
    size: `${size}`,
    filters,
    searchTerm,
    selectedSearchOption,
    goToPage: (newPage: number) => {
      queryParams.set(queryParamsName.pageNumber, `${newPage}`);
      navigate(`${generatePath(location.pathname)}?${queryParams.toString()}`);
    },
    changePageSize: (pageSize: number) => {
      queryParams.set(queryParamsName.pageNumber, `0`);
      queryParams.set(queryParamsName.pageSize, `${pageSize}`);

      navigate(`${generatePath(location.pathname)}?${queryParams.toString()}`);
    },
    onFiltersChange: (newFilter: any) => {
      queryParams.set(queryParamsName.pageNumber, `0`);
      queryParams.set(queryParamsName.filters, JSON.stringify(newFilter));

      navigate(`${generatePath(location.pathname)}?${queryParams.toString()}`);
    },
    onSearchTermChange: (newSearchOption: string, newSearchTerm: string) => {
      queryParams.set(queryParamsName.pageNumber, `0`);
      queryParams.set(queryParamsName.search, newSearchTerm);
      queryParams.set(queryParamsName.searchBy, newSearchOption);

      navigate(`${generatePath(location.pathname)}?${queryParams.toString()}`);
    }
  };
}
