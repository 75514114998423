import { useEffect } from 'react';
import { LOGIN_PATH } from '../../../AppRouter';
import { sendToLogin } from '../../../sharedComponents/Login/auth.helpers';
import { readToSessionStorage } from '../../../sharedComponents/Login/session.storage';
import { TableEmptyState } from '../../../sharedComponents/TableEmptyState';
import WelcomePageIconPath from '../../../icons/double-gear.svg';

export default function Home() {
  useEffect(() => {
    if (!readToSessionStorage('starc-sessionId')) {
      sendToLogin(LOGIN_PATH);
    }
  }, []);
  
  return (
    <TableEmptyState
        title="Welcome to CSG Advanced Rating and Charging Configuration"
        glyphPath={WelcomePageIconPath}
        hideDescription
      />
  );
}
