import { Components } from '@mui/material';
import { Theme as SystemTheme } from '@mui/system';

export const dialogTheme: Pick<
  Components<SystemTheme>,
  | 'MuiDialog'
  | 'MuiDialogActions'
  | 'MuiDialogContent'
  | 'MuiDialogTitle'
  | 'MuiDialogContentText'
> = {
  MuiDialogTitle: {
   styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.common.white,
        fontSize: theme.spacing(2.25),
        fontWeight: 'bold',
        borderBottom: `1px solid ${theme.palette.grey['300']}`
      })
    }
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        // background: theme.palette.grey['100'],
        borderTop: `1px solid ${theme.palette.grey['300']}`
      })
    }
  }
};
