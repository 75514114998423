import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { MouseEventHandler } from 'react';
import CloseIconPath from '../../icons/close_large.svg';
import { SvgIcon } from '../../sharedComponents';

const useStyles = makeStyles({
  closeIcon: {
    cursor: 'pointer'
  }
});

interface CloseIconProps {
  onClick: MouseEventHandler;
  className?: string;
}

/**Close Icon for modals, dialogs, etc */
export function CloseIcon({ onClick, className }: CloseIconProps) {
  const classes = useStyles({});

  return (
    <SvgIcon
      onClick={onClick}
      src={CloseIconPath}
      className={clsx(classes.closeIcon, className)}
    />
  );
}
