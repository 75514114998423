import {
  createApiResource,
  SearchParams
} from '../../../api/createApiResource';
import { SchemaPage } from '../../../sharedComponents/schemaPage';
import PriceMappingIconPath from '../../../icons/price-mapping.svg';
import { DataEst } from './dataEST.interfaces';

const resource = createApiResource<DataEst, SearchParams>({
  name: 'dataEST',
  search: ({ pageNumber, pageSize, filters }: SearchParams) => {
    return {
      method: 'GET',
      url: `/DataEST?${new URLSearchParams({
        page_number: pageNumber,
        page_size: pageSize,
        ...(filters?.data_index
          ? { data_index_filter: filters?.data_index }
          : {}),
        ...(filters?.subtenant ? { subtenant_filter: filters?.subtenant } : {})
      }).toString()}`,
      params: {},
      responseFormatter: (response) => {
        return response.then((data) => ({
          pageCount: data.page_count,
          recordCount: data.record_count,
          // @ts-ignore
          data: data?.search_response?.map(({ session_quota, ...item }) => ({
            ...item,
            session_quota: JSON.stringify(session_quota)
          }))
        }));
      }
    };
  },
  create: ({ session_quota, ...data }) => {
    return {
      url: '/DataEST',
      method: 'POST',
      body: JSON.stringify({
        ...data,
        session_quota: session_quota && JSON.parse(session_quota)
      })
    };
  },
  update: ({ session_quota, ...data }) => {
    return {
      url: '/DataEST',
      method: 'PUT',
      body: JSON.stringify({
        ...data,
        session_quota: session_quota && JSON.parse(session_quota)
      })
    };
  },
  remove: (data) => {
    if (data.subtenant) {
      return {
        url: `/DataEST/${encodeURIComponent(
          data.data_index
        )}/${encodeURIComponent(data.subtenant)}`
      };
    }
    return {
      url: `/DataEST/${encodeURIComponent(data.data_index)}`
    };
  },
  entitySpecUpdate: () => ({
    method: 'GET',
    url: '/EntitySpec/DataEST'
  })
});

const dataEstSchema = {
  Schema: {
    properties: {
      data_index: {
        readOnly: true
      },
      subtenant: {
        readOnly: true
      }
    }
  }
};

export function DataESTList() {
  return (
    <SchemaPage
      apiResource={resource}
      entitySpecOverrides={dataEstSchema}
      FormProps={{
        uiSchema: {
          'ui:order': [
            'data_index',
            'session_quota',
            'category',
            'subtenant',
            '*',
            'diameter_response_param',
            'meta_data',
            'additional_attributes'
          ],
          session_quota: {
            'ui:widget': 'textarea',
            'ui:options': {
              rows: 3
            }
          }
        }
      }}
      DataListProps={{
        glyphPath: PriceMappingIconPath,
        pageTitle: 'Data Price Mapping',
        searchTermOptions: [
          { label: 'Data Index', value: 'data_index' },
          { label: 'Subtenant Id', value: 'subtenant' }
        ],
        DataGridProps: {
          getRowId: (row) => row.data_index,
          columns: [
            {
              field: 'data_index',
              headerName: 'Data Index',
              flex: 3
            },
            {
              field: 'subtenant',
              headerName: 'Subtenant',
              flex: 1
            },
            {
              field: 'session_quota',
              headerName: 'Session Quota',
              renderCell: ({ row: item }) => JSON.stringify(item.session_quota),
              flex: 1
            },
            {
              field: 'diameter_response_param',
              headerName: 'Diameter Parameters',
              renderCell: ({ row: item }) =>
                JSON.stringify(item.diameter_response_param),
              flex: 1
            },
            {
              field: 'additional_attributes',
              headerName: 'Additional Attributes',
              renderCell: ({ row: item }) =>
                JSON.stringify(item.additional_attributes),
              flex: 1
            }
          ]
        }
      }}
    />
  );
}
