import { SvgIcon } from '../../sharedComponents';
import { SearchIconWrapper } from '../navigationDrawer/NavigationDrawer.styles';
import TimesCirclePath from '../../icons/times.circle.svg';

export interface ClearSearchIconProps {
  onClick: () => void;
}
export function ClearSearchIcon({ onClick }: ClearSearchIconProps) {
  return (
    <SearchIconWrapper onClick={onClick}>
      <SvgIcon
        src={TimesCirclePath}
        sx={(theme) => ({
          fill: theme.palette.common.white,
          transform: 'translateY(-50%)',
          width: '18px'
        })}
      />
    </SearchIconWrapper>
  );
}
