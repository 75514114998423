import {
  createApiResource,
  SearchParams
} from '../../../api/createApiResource';
import { SchemaPage } from '../../../sharedComponents/schemaPage';
import OnlineProcessingIconPath from '../../../icons/online-processing.svg';
import { OutboundActions } from './outboundActions.interfaces';

const resource = createApiResource<OutboundActions, SearchParams>({
  name: 'outboundActions',
  search: ({ pageNumber, pageSize, filters }: SearchParams) => {
    return {
      method: 'GET',
      url: `/OutBoundActions?${new URLSearchParams({
        page_number: pageNumber,
        page_size: pageSize,
        ...(filters?.context ? { context_filter: filters?.context } : {})
      }).toString()}`,
      params: {},
      responseFormatter: (response) => {
        return response.then((data) => ({
          pageCount: data.page_count,
          recordCount: data.record_count,
          data: data?.search_response
        }));
      }
    };
  },
  create: () => {
    return {
      url: '/OutBoundActions',
      method: 'POST'
    };
  },
  update: () => {
    return {
      url: '/OutBoundActions',
      method: 'PUT'
    };
  },
  remove: (data) => {
    return {
      url: `/OutBoundActions/${encodeURIComponent(data.context)}`
    };
  },
  entitySpecUpdate: () => ({
    method: 'GET',
    url: '/EntitySpec/OutBoundActions'
  })
});

const outboundSchema = {
  Schema: {
    properties: {
      context: {
        readOnly: true
      }
    }
  }
};

export function OutboundActionsList() {
  return (
    <SchemaPage
      entitySpecOverrides={outboundSchema}
      apiResource={resource}
      FormProps={{
        uiSchema: {
          'ui:order': ['context', 'action', 'category']
        }
      }}
      DataListProps={{
        glyphPath: OnlineProcessingIconPath,
        searchTermOptions: [{ label: 'Context', value: 'context' }],
        pageTitle: 'Outbound Actions',
        DataGridProps: {
          getRowId: (row) => row.context,
          columns: [
            {
              field: 'context',
              headerName: 'Context',
              flex: 1
            },

            {
              field: 'action',
              headerName: 'Action',
              flex: 1
            },
            {
              field: 'category',
              headerName: 'Category',
              flex: 1
            }
          ]
        }
      }}
    />
  );
}
