import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  BULK_FILE_SIZE_LIMIT,
  BULK_FILE_TYPE,
  getConfigurationOperationType
} from '../configurationFileLoader.constants';
import {
  ConfigurationBulkUploadProps,
  FileProps,
  MultipartUploadResponse,
  OperationDropdownItem
} from './configurationFileLoader.BulkUpload.interface';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import { InfoToolTip } from '../../../sharedComponents/ToolTip';
import { useNotification } from '../../../hooks/notify.hook';
import {
  bulkUpload,
  completeMultipartUpload,
  multipartUpload,
  searchConfigurationFileLoader,
  startMultiPartUpload
} from './configurationFileLoader.BulkUpload.resource';
import { useMutation } from '@tanstack/react-query';
import { PageLoading } from '../../../sharedComponents/PageLoading';
import { readToSessionStorage } from '../../../sharedComponents/Login/session.storage';
import { sendToLogin } from '../../../sharedComponents/Login/auth.helpers';
import { LOGIN_PATH } from '../../../AppRouter';

const ChooseFileLink = styled(Box)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const FileInfoMessage = styled(Box)`
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;

const FileInputBox = styled(Input)`
  left: 0;
  margin-top: ${(props) => props.theme.spacing(3)};
  opacity: 0;
  position: absolute;
  right: 0;
  width: 25%;
  z-index: 10;
`;

const FileUploadButtonReplacement = styled(Box)`
  align-items: center;
  background-color: transparent;
  background-image: none;
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1.125;
  z-index: 5;
`;

const FileUploadFieldContainer = styled(Box)`
  margin-bottom: ${(props) => props.theme.spacing(1)};
  position: relative;
  width: 100%;
`;

const InfoToolTipStyled = styled(InfoToolTip)`
  left: 25%;
  position: absolute;
`;

export default function ConfigurationBulkUpload({
  isOpen,
  onClose
}: ConfigurationBulkUploadProps) {
  const [configFiles, setConfigFiles] = useState<FileProps[]>([]);
  const [isWrongFileType, setWrongFileType] = useState<boolean>(false);
  const { success, error } = useNotification();
  const [allPartTags, setAllPartTags] = useState<MultipartUploadResponse[]>([]);
  const configOperationTypes = getConfigurationOperationType();
  const [configOperation, setConfigOperation] = useState<OperationDropdownItem>(
    configOperationTypes[0]
  );
  const {
    mutate: mutateStartMultiPartUpload,
    isLoading: isStartMultiParUploaded
  } = useMutation(startMultiPartUpload);
  const { mutate: mutateMultiPartUpload, isLoading: isMultiPartUploaded } =
    useMutation(multipartUpload);
  const {
    mutate: mutateCompleteMultipartUpload,
    isLoading: isCompleteMultiPartUploaded
  } = useMutation(completeMultipartUpload);
  const { mutate: mutateBulkUpload, isLoading: isBulkFileUploaded } =
    useMutation(bulkUpload);
  const { mutate: mutateSearchConfigFileLoader } = useMutation(
    searchConfigurationFileLoader
  );

  useEffect(() => {
    if (!readToSessionStorage('starc-sessionId')) {
      sendToLogin(LOGIN_PATH);
    }
  }, []);
  
  function onFileSelect(e: any) {
    const { files } = e.target;
    if (files && files[0] && files[0].type === BULK_FILE_TYPE) {
      setConfigFiles(files);
      setWrongFileType(false);
    } else {
      setWrongFileType(true);
      setConfigFiles([]);
    }
  }

  function onUploadCancel() {
    onClose();
    setConfigFiles([]);
    setWrongFileType(false);
    setConfigOperation(configOperationTypes[0]);
  }

  function startUploadFile() {
    if (allPartTags.length) {
      setAllPartTags([]);
    }
    mutateStartMultiPartUpload(
      { fileName: configFiles[0].name },
      {
        onSuccess: (startMultipartResponse) => {
          multiPartUploadFile(startMultipartResponse);
        },
        onError: () => {
          error('Bulk file has been Failed to submit for processing');
        }
      }
    );
  }

  function multiPartUploadFile(multiPartResponse: any) {
    const file = (document.querySelector('#file') as HTMLInputElement)
      .files?.[0]!;
    const { size } = file;
    const sliceSize = BULK_FILE_SIZE_LIMIT;
    let start = 0;
    loop();
    function loop() {
      let end = start + sliceSize;
      const s = file.slice(start, end);
      mutateMultiPartUpload(
        {
          fileName: configFiles[0].name,
          uploadId: multiPartResponse?.UploadId,
          partNumber: allPartTags.length + 1,
          inputData: s
        },
        {
          onSuccess: (multipartResponse) => {
            allPartTags.push({
              PartNumber: multipartResponse.PartNumber,
              ETag: multipartResponse.ETag
            });
            if (size - end < 0) {
              end = size;
              const response = allPartTags.map((res, index) => {
                return {
                  ETag: res.ETag,
                  PartNumber: index + 1
                };
              });
              const uploadId = multiPartResponse.UploadId;
              completeMultiPartUpdateAndBulkLoad(uploadId, response);
            }
            if (end < size) {
              start += sliceSize;
              loop();
            }
          },
          onError: () => {
            error('Bulk file has been Failed to submit for processing');
          }
        }
      );
    }
  }

  function completeMultiPartUpdateAndBulkLoad(
    uploadId: string,
    uploadConfigRes: MultipartUploadResponse[]
  ) {
    mutateCompleteMultipartUpload(
      {
        fileName: configFiles[0].name,
        uploadId,
        partTags: uploadConfigRes
      },
      {
        onSuccess: () => {
          mutateBulkUpload(
            {
              fileName: configFiles[0].name,
              operation:
                configOperation?.value === 'SELECT'
                  ? undefined
                  : configOperation?.value
            },
            {
              onSuccess: (bulkUploadResponse) => {
                if (bulkUploadResponse?.message) {
                  error(
                    bulkUploadResponse?.message ||
                      'Bulk file has been Failed to submit for processing'
                  );
                } else {
                  success('Bulk file has been submitted for Processing');
                }
                mutateSearchConfigFileLoader(
                  { pageSize: 25, pageNumber: 1 },
                  {
                    onSuccess: () => {
                      onUploadCancel();
                      setAllPartTags([]);
                    },
                    onError: () => {
                      error(
                        'Bulk file has been Failed to submit for processing'
                      );
                    }
                  }
                );
              },
              onError: () => {
                error('Bulk file has been Failed to submit for processing');
                setAllPartTags([]);
              }
            }
          );
        },
        onError: () => {
          error('Bulk file has been Failed to submit for processing');
        }
      }
    );
  }

  const handleChange = (event: SelectChangeEvent) => {
    setConfigOperation(
      configOperationTypes.find(
        (config: OperationDropdownItem) => config.value === event.target.value
      ) || configOperationTypes[0]
    );
  };
  return (
    <>
      <Dialog
        open={isOpen}
        title={'Add Configuration File'}
        onClose={() => onUploadCancel()}
        fullWidth
        maxWidth="xs"
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            Add Configuration File
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box p={2} overflow="auto">
            <FileUploadFieldContainer>
              <FileInputBox
                id="file"
                type="file"
                inputProps={{
                  accept: BULK_FILE_TYPE
                }}
                onChange={onFileSelect}
              />
              <FileInfoMessage>{'Select a file to upload'}</FileInfoMessage>
              <FileUploadButtonReplacement>
                <ChooseFileLink>{'Choose a File'}</ChooseFileLink>
                <InfoToolTipStyled
                  info={<>{'Only JSON files are supported.'}</>}
                />
                <Box>
                  {configFiles[0] ? configFiles[0]?.name : 'No file chosen'}
                </Box>
              </FileUploadButtonReplacement>
              {isWrongFileType ? (
                <Box color={(theme) => theme.palette.error.main}>
                  The configuration file should be in .json format.
                </Box>
              ) : null}
            </FileUploadFieldContainer>
            <Box display="flex" justifyContent="space-between">
              <Box>Operation</Box>
              <Select
                value={configOperation?.value}
                onChange={handleChange}
                size="small"
              >
                {configOperationTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => onUploadCancel()}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={isWrongFileType || !configFiles.length}
            onClick={startUploadFile}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <PageLoading
        open={
          isStartMultiParUploaded ||
          isMultiPartUploaded ||
          isCompleteMultiPartUploaded ||
          isBulkFileUploaded
        }
      />
    </>
  );
}
