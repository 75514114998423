import { ListItemIcon } from '@mui/material';
import { forwardRef } from 'react';
import { SvgIcon, SvgIconProps } from '../../sharedComponents/Icon';
export interface NavigationListIconProps {
  selected: boolean;
  open?: boolean;
  src?: SvgIconProps['src'];
}

export const NavigationListIcon = forwardRef(
  (props: NavigationListIconProps, ref) => {
    const { selected, src } = { ...props };
    return (
      <ListItemIcon
        sx={{
          minWidth: 0
        }}
        ref={ref}
        {...props}
      >
        {src && (
          <SvgIcon
            src={src}
            sx={(theme) => ({
              width: '34px',
              height: '34px',
              marginLeft: theme.spacing(0.5),
              padding: theme.spacing(1),
              fill: selected ? theme.palette.common.white : theme.palette.grey[500]
            })}
          />
        )}
      </ListItemIcon>
    );
  }
);
