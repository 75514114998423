import { useQuery } from '@tanstack/react-query';
import { ApiResource } from '../../api/createApiResource';

export function getListDataQueryKey(apiResourceName: string) {
  return ['listData', apiResourceName];
}

export function useListDataQuery({
  apiResource,
  searchOptions
}: {
  apiResource: ApiResource;
  searchOptions: Record<string, string>;
}) {
  return useQuery([...getListDataQueryKey(apiResource.name), searchOptions], {
    queryFn: () => {
      return apiResource?.search(searchOptions);
    },
    refetchOnWindowFocus: false
  });
}
