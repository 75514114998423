import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { SvgIcon } from '../Icon';
import { useForm } from './useForm';
import CSGLoginLogoPath from '../../icons/csg-login-logo.svg';
import ExclamationPath from '../../icons/exclamation-circle.svg';
import CrossErrorIconPath from '../../icons/cross-error-icon.svg';
import { makeStyles } from '@mui/styles';
import { styled, Theme } from '@mui/material/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { authenticateLogin } from './loginResource';
import { PageLoading } from '../PageLoading';
import { useMutation } from '@tanstack/react-query';
import { AUTHENTICATION_REDIRECT_MESSAGE } from './auth.helpers';
import { readToSessionStorage } from './session.storage';
import { ThemeOptionsOverides } from '../../theme/theme.interfaces';

const ErrorMessageIconStyled = styled(Box)`
  display: flex;
  width: 100%;
  padding-left: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) => theme.palette.error.dark};
`;

const LoginWrapperStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ApiErrorStyled = styled(Box)`
  display: flex;
  padding: ${({ theme }) => theme.spacing(2)};
  gap: ${({ theme }) => theme.spacing(1)};
  border: ${({ theme }) => `1px solid ${theme.palette.error.main}`};
  background-color: ${({ theme }: { theme?: ThemeOptionsOverides }) =>
    `${theme?.palette?.canvas.errorLight}`};
  width: 312px;
  height: 56px;
  border-radius: ${({ theme }) => theme.spacing(0.5)};
`;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.MuiFormControl-root': {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(1)
    },
    '& .MuiInputBase-root': {
      height: '44px'
    },
    '&.MuiOutlinedInput-root': {
      height: '44px',
      margin: theme.spacing(1)
    }
  }
}));

export interface User {
  name: string;
  password: string;
}

const Login: FC = () => {
  const classes = useStyles({});
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const isRedirectMessageExists =
    sessionStorage.getItem('strac-authentication-redirect') &&
    readToSessionStorage(AUTHENTICATION_REDIRECT_MESSAGE);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [isLoginModelClosed, setIsLoginModelClosed] = useState(false);
  const { mutate, isLoading } = useMutation(authenticateLogin);
  const [apiError, setApiError] = useState('');
  const [redirectMessage, setRedirectMessage] = useState('');

  const {
    handleSubmit,
    handleChange,
    data: user,
    errors
  } = useForm<User>({
    validations: {
      name: {
        required: {
          value: true,
          message: 'Email is required.'
        },
        custom: {
          isValid: (value) => value?.length > 6,
          message: '6 charcters minimum'
        }
      },
      password: {
        required: {
          value: true,
          message: 'Password is required.'
        },
        custom: {
          isValid: (value) => value?.length > 6,
          message: '6 charcters minimum.'
        }
      }
    },
    onSubmit: (value) => {
      mutate(value, {
        onSuccess: () => {
          setIsLoginModelClosed(true);
        },
        onError: (error: any) => {
          if (error?.error_description) {
            setApiError('Incorrect email or password');
          }
        }
      });
    }
  });

  useEffect(() => {
    setRedirectMessage(isRedirectMessageExists || '');
  }, [isRedirectMessageExists]);

  return (
    <>
      <Dialog
        PaperProps={{ sx: { borderRadius: 3 } }}
        scroll="paper"
        open={!isLoginModelClosed}
      >
        <DialogContent>
          <form
            id="loginForm"
            data-cy="loginForm"
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
          >
            <LoginWrapperStyled>
              <SvgIcon
                src={CSGLoginLogoPath}
                sx={(theme) => ({
                  marginY: theme.spacing(4),
                  '&::after': {
                    content: '""',
                    marginTop: theme.spacing(4),
                    borderBottom: `1px solid ${theme.palette.grey[200]}`,
                    position: 'absolute',
                    left: 0,
                    right: 0
                  }
                })}
              />
              <Box>
                <Typography
                  variant="subtitle2"
                  sx={(theme) => ({
                    width: '309px',
                    fontWeight: theme.typography.fontWeightMedium,
                    color: 'rgba(0, 20, 43, 0.64)',
                    fontSize: theme.spacing(2),
                    paddingY: theme.spacing(2.5)
                  })}
                >
                  {redirectMessage ||
                    'Enter your email and password to continue'}
                </Typography>
              </Box>
              {apiError && (
                <ApiErrorStyled>
                  <SvgIcon
                    src={CrossErrorIconPath}
                    sx={(theme) => ({
                      fill: theme.palette.error.main,
                      fontSize: theme.spacing(2.5),
                      height: theme.spacing(2.5),
                      width: theme.spacing(2.5)
                    })}
                  />
                  {apiError}
                </ApiErrorStyled>
              )}

              <TextField
                autoFocus
                className={classes.root}
                fullWidth
                placeholder="Email"
                data-cy="login-email"
                value={user.name}
                onChange={handleChange('name')}
                required
                error={!!errors.name}
              />
              {errors.name && (
                <ErrorMessageIconStyled>
                  <SvgIcon
                    src={ExclamationPath}
                    sx={({ palette }) => ({
                      fill: palette.error.main,
                      width: '14px',
                      height: '14px'
                    })}
                  />
                  <Box ml={(theme) => theme.spacing(1)}>{errors.name}</Box>
                </ErrorMessageIconStyled>
              )}
              <OutlinedInput
                value={user.password}
                data-cy="login-password"
                onChange={handleChange('password')}
                required
                error={!!errors.password}
                className={classes.root}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      data-cy="toggle-password-visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errors.password && (
                <ErrorMessageIconStyled>
                  <SvgIcon
                    src={ExclamationPath}
                    sx={({ palette }) => ({
                      fill: palette.error.main,
                      width: '14px',
                      height: '14px'
                    })}
                  />
                  <Box paddingLeft={(theme) => theme.spacing(1)}>
                    {errors.password}
                  </Box>
                </ErrorMessageIconStyled>
              )}
              <Button
                type="submit"
                form="loginForm"
                variant="contained"
                fullWidth
                sx={(theme) => ({
                  textTransform: 'unset',
                  margin: `${theme.spacing(1)} 0`
                })}
                data-cy="login-submit"
              >
                Log in to CSG-ARC
              </Button>
              <Button fullWidth color="primary" data-cy="login-forgotPassword">
                Forgot password?
              </Button>
            </LoginWrapperStyled>
          </form>
        </DialogContent>
      </Dialog>
      <PageLoading open={isLoading} />
    </>
  );
};

export default Login;
