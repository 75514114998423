import Grid from '@mui/material/Grid';
import {
  FormContextType,
  ObjectFieldTemplateProps,
  RJSFSchema,
  StrictRJSFSchema,
  canExpand
} from '@rjsf/utils';

/** The `ObjectFieldTemplate` is the template to use to render all the inner properties of an object along with the
 * title and description if available. If the object is expandable, then an `AddButton` is also rendered after all
 * the properties.
 *
 * @param props - The `ObjectFieldTemplateProps` for this component
 */
export default function ObjectFieldTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: ObjectFieldTemplateProps<T, S, F>) {
  const {
    properties,
    disabled,
    readonly,
    uiSchema,
    schema,
    formData,
    onAddClick,
    registry
  } = props;

  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton }
  } = registry.templates;
  return (
    <>
      <Grid container={true} spacing={1} marginY={1}>
        {properties.map((element, index) =>
          // Remove the <Grid> if the inner element is hidden as the <Grid>
          // itself would otherwise still take up space.
          element.hidden ? (
            element.content
          ) : (
            <Grid item={true} xs={12} key={index}>
              {element.content}
            </Grid>
          )
        )}
        {canExpand<T, S, F>(schema, uiSchema, formData) && (
          <Grid container justifyContent="flex-end">
            <Grid item>
              <AddButton
                data-cy="add-button"
                className="object-property-expand"
                onClick={onAddClick(schema)}
                disabled={disabled || readonly}
                uiSchema={uiSchema}
                //  @ts-ignore
                registry={registry}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
