import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { logOut } from '../../App';
import { AuthenticationMessages } from '../../constants/common.constants';
import { ThemeOverrides } from '../../theme/theme.interfaces';

// https://codesandbox.io/s/confirm-prompt-forked-5ujv94?file=/src/App.tsx
const LoggedInButtonStyled = styled(Button)`
  background: ${(props: ThemeOverrides) =>
    props.theme?.palette?.border.success};
  &:hover {
    background: ${(props: ThemeOverrides) =>
      props.theme?.palette?.border.success};
  }
`;

/**
 * Runs a timer that shows a modal and will log the user out due to inactivity
 */
const timeout = 20 * 60 * 1000;
const promptBeforeIdle = 30 * 1000;

export function ActivityTimer() {
  const [remaining, setRemaining] = useState<number>(timeout);

  const [open, setOpen] = useState<boolean>(false);

  const onIdle = () => {
    setOpen(false);
    logOut(AuthenticationMessages.inactivityLogOut);
  };

  const onActive = () => {
    setOpen(false);
  };

  const onPrompt = () => {
    //on dialog pop up open
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    events: ['load']
  });

  // for last 30 seconds timer.
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  return (
    <div>
      <Dialog
        title="Your session is about to expire"
        onClose={handleStillHere}
        open={open}
        fullWidth
        maxWidth="xs"
        disableEscapeKeyDown
      >
        <DialogContent>
          <Box>
            <Typography
              variant="h3"
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightBold,
                color: 'rgba(0, 20, 43, 0.88)',
                fontSize: theme.spacing(2.5)
              })}
            >
              Your session is about to expire
            </Typography>
            <Typography
              variant="subtitle1"
              sx={(theme) => ({
                marginTop: theme.spacing(3),
                color: theme.palette.grey[700],
                fontWeight: theme.typography.fontWeightMedium,
                lineHeight: theme.spacing(2.5),
                fontSize: theme.spacing(2)
              })}
            >
              <Box>Your session is about to expire due to inactivity.</Box>
              <Box marginTop={(theme: Theme) => theme.spacing(1)}>
                {/*  getRemaining Func doesn't returns 30 val as seconds, so handling through small tweak*/}
                Time Remaining:{' '}
                <b>{remaining === 31 ? 30 : remaining} seconds</b>
              </Box>
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={()=>logOut(AuthenticationMessages.successfulLogOut)}
            variant="text"
            color="info"
            sx={(theme) => ({
              textTransform: 'unset',
              color: theme.palette.grey[700]
            })}
            data-cy="activityTimerDialog-logout"
          >
            Logout now
          </Button>
          <LoggedInButtonStyled
            sx={(theme) => ({
              textTransform: 'unset',
              '&::hover': {
                background: 'red'
              }
            })}
            onClick={handleStillHere}
            variant="contained"
            color="info"
            data-cy="activityTimerDialog-stayLoggedIn"
          >
            Stay Logged In
          </LoggedInButtonStyled>
        </DialogActions>
      </Dialog>
    </div>
  );
}
