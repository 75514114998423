import { useEffect, useRef, useState } from 'react';
import {
  Box,
  List,
  ListItemText,
  Collapse,
  Toolbar,
  Tooltip
} from '@mui/material';
import {
  useNavigate,
  generatePath,
  matchPath,
  useLocation
} from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Drawer,
  ListItemButtonStyled,
  ListWrapperStyled,
  SearchInput,
  SearchInputContainer,
  ToolTipContainer,
  ToolTipLink,
  useStyles
} from './NavigationDrawer.styles';
import {
  drawerWidth,
  leftNavWidth,
  navigationItems
} from './navigationDrawer.constants';
import { NavigationListIcon } from './NavigationIcon';
import { SvgIcon } from '../../sharedComponents';
import { ClearSearchIcon } from '../components/clearSearchIcon.component';
import { ParentChildNav } from './navigation.interfaces';
import SidePanelCloseIconPath from '../../icons/sidepanel-close.svg'
import SidePanelOpenIconPath from '../../icons/sidepanel-open.svg'
import SearchIconPath from '../../icons/search-icon.svg';
import { ThemeOverrides } from '../../theme/theme.interfaces';

const SUBMIT = 'submit';

const SidebarIconStyled = styled(SvgIcon)`
  height: ${(props) => props.theme.spacing(2)};
  width: ${(props) => props.theme.spacing(2)};
  font-size: 0.875rem;
  fill: ${(props: ThemeOverrides) => props.theme?.palette?.grey?.[500]}; 
`;

export function NavigationDrawer() {
  const inputRef = useRef(null);
  const [activeNav, setActiveNav] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles({});
  const [open, setOpen] = useState(true);
  const [selectedParentNav, setSelectedParentNav] = useState('');
  const [searchText, setSearchText] = useState('');
  const [filteredNavItems, setFilteredNavItems] =
    useState<ParentChildNav[]>(navigationItems);
  const [activeSearch, setActiveSearch] = useState(false);

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  function filterNavItems() {
    const filtered: ParentChildNav[] = [];
    navigationItems.forEach((parent) => {
      const { ...parentCopy } = parent;
      parentCopy.children = [];
      parent.children?.forEach((child) => {
        if (child.name.toLowerCase().includes(searchText.toLowerCase())) {
          parentCopy.children?.push(child);
        }
      });
      if (parentCopy.children?.length > 0) {
        filtered.push(parentCopy);
      }
    });
    setFilteredNavItems(filtered);
  }

  function performSearch(e: any) {
    if (e.type === SUBMIT) {
      e.preventDefault();
    }

    if (searchText) {
      setActiveSearch(true);
      filterNavItems();
    } else {
      resetNav();
    }
  }

  function resetNav() {
    setActiveSearch(false);
    setFilteredNavItems(navigationItems);
  }

  useEffect(() => {
    setFilteredNavItems(navigationItems);
  }, []);

  useEffect(() => {
    if (!open) {
      setSearchText('');
      setFilteredNavItems(navigationItems);
    }
  }, [open]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    const selectedNavName = navigationItems.find((navItem, parentNavIndex) => {
      if (navItem.children) {
        return navItem.children.find((navChild) => {
          if (!!matchPath(navChild.path, location.pathname)) {
            setSelectedParentNav(navigationItems[parentNavIndex].name);
            return true;
          }
          return false;
        });
      }
    })?.name;
    setActiveNav(selectedNavName);
  }, [location.pathname]);

  return (
    <Drawer
      variant="permanent"
      open={open}
      className={classes.root}
      sx={{
        width: drawerWidth,
        flexShrink: 0
      }}
    >
      <Toolbar />
      <ListWrapperStyled>
        <Box>
          {open && (
            <SearchInputContainer>
              <form onSubmit={performSearch}>
                <Box display="flex">
                  <SvgIcon
                    src={SearchIconPath}
                    sx={{
                      marginTop: '12px',
                      marginLeft: '4px'
                    }}
                  />
                  <SearchInput
                    data-cy="nav-search"
                    type="text"
                    onBlur={performSearch}
                    placeholder={'Search...'}
                    value={searchText}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                  {searchText && (
                    <ClearSearchIcon
                      data-cy="nav-clear"
                      onClick={() => {
                        setSearchText('');
                        resetNav();
                      }}
                    />
                  )}
                </Box>
              </form>
            </SearchInputContainer>
          )}
          {filteredNavItems.map(({ children, name, glyphPath }, index) => (
            <Box key={name}>
              <ListItemButtonStyled
                data-cy={`nav-button-${name}`}
                onClick={() => setActiveNav(name)}
                sx={{
                  [`&.MuiButtonBase-root.MuiListItemButton-root`]: {
                    '.Mui-selected': {
                      background: 'transparent'
                    }
                  }
                }}
              >
                {open ? (
                  <NavigationListIcon
                    src={glyphPath}
                    ref={inputRef}
                    selected={selectedParentNav === name}
                  />
                ) : (
                  <Tooltip
                    className={classes.root}
                    PopperProps={{
                      sx: (theme) => ({
                        '& .MuiTooltip-tooltip': {
                          marginLeft: 0,
                          background: theme.palette.common.white,
                          boxShadow:
                            'rgb(79 91 103 / 50%) 0px 0.125rem 0.3125rem'
                        }
                      }),
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -4]
                          }
                        }
                      ]
                    }}
                    title={
                      <ToolTipContainer>
                        <strong>{name}</strong>
                        {children?.map((child) => (
                          <ToolTipLink
                            sx={(theme: any) => ({
                              color: !!matchPath(child.path, location.pathname)
                                ? theme?.palette?.navigation
                                    ?.rightHeaderBackground
                                : theme?.palette.common.dark
                            })}
                            title={child.name}
                            key={child.path}
                            to={child.path}
                          >
                            {child.name}
                          </ToolTipLink>
                        ))}
                      </ToolTipContainer>
                    }
                    placement="right-start"
                    arrow
                  >
                    <NavigationListIcon
                      src={glyphPath}
                      ref={inputRef}
                      selected={selectedParentNav === name}
                    />
                  </Tooltip>
                )}
                <ListItemButtonStyled
                  data-cy={name}
                  className={classes.root}
                  selected={selectedParentNav === name}
                  sx={{
                    justifyContent: open ? 'initial' : 'center'
                  }}
                >
                  <ListItemText
                    className={classes.root}
                    primary={name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButtonStyled>
              </ListItemButtonStyled>
              {open && !!children?.length && (
                <Collapse
                  in={activeNav === name || activeSearch}
                  timeout="auto"
                  unmountOnExit
                  sx={{
                    marginLeft: `${leftNavWidth}px`
                  }}
                >
                  <List>
                    {children.map((childNav) => (
                      <ListItemButtonStyled
                        data-cy={`nav-button-${childNav.name}`}
                        sx={{
                          [`&.MuiButtonBase-root.MuiListItemButton-root`]: {
                            minHeight: '28px',
                            paddingLeft: '1.5'
                          }
                        }}
                        className={classes.root}
                        key={childNav.name}
                        onClick={() => {
                          setSelectedParentNav(navigationItems[index].name);
                          navigate(generatePath(childNav.path));
                        }}
                        selected={!!matchPath(childNav.path, location.pathname)}
                      >
                        <ListItemText
                          className={classes.root}
                          primary={childNav.name}
                          sx={{
                            [`& .MuiTypography-root.MuiListItemText-primary`]: {
                              fontWeight: '400'
                            },
                            opacity: open ? 1 : 0
                          }}
                        />
                      </ListItemButtonStyled>
                    ))}
                  </List>
                </Collapse>
              )}
            </Box>
          ))}
        </Box>
        <Box display="flex" justifyContent="end">
          <ListItemButtonStyled
            onClick={handleDrawerClose}
            data-cy={open ? 'collapsed-side-bar': 'open-side-bar'}
            sx={(theme) => ({
              marginBottom: theme.spacing(1.5),
              marginRight: open ? theme.spacing(1) : 0,
              gap: 1,
              alignItems: 'center',
              justifyContent: open ? 'right' : 'center'
            })}
          >
            { open ? 
            <>
              Collapse sidebar
              <SidebarIconStyled src={SidePanelCloseIconPath} />
            </>
            :
            <SidebarIconStyled src={SidePanelOpenIconPath} />}
          </ListItemButtonStyled>
        </Box>
      </ListWrapperStyled>
    </Drawer>
  );
}
