import {
  InputAdornment,
  OutlinedInput,
  Theme,
  useForkRef
} from '@mui/material';
import React, { useRef } from 'react';
import SearchIconPath from '../icons/search.svg';
import TimesCircleIconPath from '../icons/times-circle.svg';
import { SvgIcon } from '../sharedComponents';

function setNativeValue(element: HTMLInputElement, value: string) {
  const valueSetter = Object.getOwnPropertyDescriptor(element, 'value')?.set;
  const prototype = Object.getPrototypeOf(element);
  const prototypeValueSetter = Object.getOwnPropertyDescriptor(
    prototype,
    'value'
  )?.set;

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter?.call(element, value);
  } else {
    valueSetter?.call(element, value);
  }
}

/**
 * Input Field with search icon and watermark
 */
export function SearchBox({ inputRef, ...props }: any) {
  const ref = useRef<HTMLInputElement>();
  const setRef = useForkRef(ref, inputRef || (() => {}));

  function clearSearchBox() {
    const event = new Event('input', { bubbles: true });
    setNativeValue(ref.current!, '');
    ref.current?.dispatchEvent(event);
  }
  return (
    <OutlinedInput
      {...props}
      style={{ flexGrow: 1, marginRight: 0 }}
      inputRef={setRef}
      placeholder={props.placeholder || 'Search...'}
      startAdornment={
        <InputAdornment position="start" data-cy="searchBox-input">
          <SvgIcon
            src={SearchIconPath}
            sx={(theme: Theme) => ({
              fill: theme.palette.grey[500],
              height: '16px',
              width: '16px'
            })}
          />
        </InputAdornment>
      }
      endAdornment={
        // eslint-disable-next-line react/destructuring-assignment
        props?.value ? (
          <InputAdornment
            data-cy="searchBox-clearSearch"
            position="end"
            onClick={clearSearchBox}
            style={{
              cursor: 'pointer'
            }}
          >
            <SvgIcon
              src={TimesCircleIconPath}
              sx={(theme) => ({
                fill: theme.palette.grey[500],
                height: '16px',
                width: '16px'
              })}
            />
          </InputAdornment>
        ) : null
      }
    />
  );
}
