import { Theme } from '@rjsf/mui';
import { withTheme } from '@rjsf/core';
import { customizeValidator } from '@rjsf/validator-ajv6';
import { FormProps } from '@rjsf/core';
import { ApiResource } from '../../api/createApiResource';
import { useQuery } from '@tanstack/react-query';
import toJsonSchema from '@openapi-contrib/openapi-schema-to-json-schema';
import $RefParser from '@apidevtools/json-schema-ref-parser';
import FieldTemplate from './templates/FieldTemplate';
import BaseInputTemplate from './templates/BaseInputTemplate';
import ObjectFieldTemplate from './templates/ObjectFieldTemplate';
import ArrayFieldItemTemplate from './templates/ArrayFieldItemTemplate';
import ArrayFieldTemplate from './templates/ArrayFieldTemplate';
import ArrayFieldTitleTemplate from './templates/ArrayFieldTitleTemplate';
import { merge } from 'lodash';
import { EntitySpecOverride } from '../schemaPage/schemaPageInterfaces';
import { RJSFValidationError } from '@rjsf/utils';
import {
  ErrorListTemplate,
  getErrorLabel
} from './templates/ErrorListTemplate';

//https://rjsf-team.github.io/react-jsonschema-form/docs/usage/validation
const metaSchemaDraft04 = require('ajv/lib/refs/json-schema-draft-04.json');
function isJSON(str: string) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

const validator = customizeValidator({
  additionalMetaSchemas: [metaSchemaDraft04],
  customFormats: {
    json: (data) => !!isJSON(data)
  },
  ajvOptionsOverrides: {
    addUsedSchema: false
  }
});
export interface RjsFormProps
  extends Omit<FormProps, 'validator' | 'noHtml5Validate' | 'schema'> {
  apiResource: ApiResource;
  entitySpecOverrides?: EntitySpecOverride;
}

const MuiForm = withTheme({
  ...Theme,
  templates: {
    ...Theme.templates,
    ArrayFieldItemTemplate: ArrayFieldItemTemplate,
    ArrayFieldTemplate: ArrayFieldTemplate,
    ArrayFieldTitleTemplate: ArrayFieldTitleTemplate,
    ObjectFieldTemplate: ObjectFieldTemplate,
    FieldTemplate: FieldTemplate,
    BaseInputTemplate: BaseInputTemplate,
    ErrorListTemplate: ErrorListTemplate
  },
  widgets: {
    ...Theme.widgets
  }
});

function fixSchema(
  dereferencedSchema: $RefParser.JSONSchema
): $RefParser.JSONSchema {
  // @ts-ignore
  const { nullable, properties, ...schema } = dereferencedSchema;
  return {
    ...schema,
    ...(properties !== undefined
      ? {
          properties: Object.entries(properties).reduce(
            (accumulator, [key, value]) => ({
              ...accumulator,
              // @ts-ignore
              [key]: fixSchema(value)
            }),
            {}
          )
        }
      : {}),
    ...(nullable === 'true' ? { nullable: true } : {})
  };
}

export function extendEntitySpec(
  entitySpec: $RefParser.JSONSchema,
  extension: RjsFormProps['formData']
) {
  if (!entitySpec) {
    return null;
  }
  const result = merge({}, entitySpec, extension?.Schema) as EntitySpecOverride;
  if (extension?.Schema?.required) {
    result.Schema.required = extension.Schema.required;
  }
  return result;
}

function getJsonSchema(
  openApiSchema: $RefParser.JSONSchema,
  entitySpecOverrides?: EntitySpecOverride,
  formData?: RjsFormProps['formData']
) {
  const entitySpecExtension = extendEntitySpec(
    openApiSchema,
    formData ? entitySpecOverrides : null
  );

  return new Promise((resolve) =>
    $RefParser.dereference(
      {
        ...entitySpecExtension,
        // @ts-ignore
        definitions: entitySpecExtension['x-definitions']
      },
      (_error, newSchema) => {
        if (newSchema) {
          resolve(
            toJsonSchema(fixSchema(newSchema), {
              keepNotSupported: ['readOnly', 'nullable']
            })
          );
        }
      }
    )
  );
}

export function RjsForm({
  entitySpecOverrides,
  apiResource,
  uiSchema,
  ...props
}: RjsFormProps) {
  const { data: updateSchema } = useQuery(
    ['updateSchema', apiResource.name],
    () => {
      return typeof apiResource.entitySpecUpdate === 'function'
        ? apiResource.entitySpecUpdate().then((schemaResponse: any) =>
            // @ts-ignore
            getJsonSchema(
              schemaResponse?.Schema,
              entitySpecOverrides,
              props.formData
            )
          )
        : getJsonSchema(
            apiResource.entitySpecUpdate,
            entitySpecOverrides,
            props.formData
          );
    }
  );

  function transformErrors(errors: RJSFValidationError[]) {
    if (updateSchema) {
      const schemaObj = updateSchema as $RefParser.JSONSchema;
      const labelErrors = getErrorLabel(schemaObj?.properties, errors);
      return (labelErrors || []).map((labelError) => {
        if (labelError.error.name === 'required') {
          labelError.error.message = `${labelError.label} is a required field.`;
        }
        if (labelError.error.name === 'format') {
          labelError.error.message = `${labelError.label} is not valid JSON format.`;
        }
        if (labelError.error.name === 'type') {
          labelError.error.message = `${labelError.label} ${labelError.error.message}.`;
        }
        return labelError.error;
      });
    }
    return errors;
  }

  return !updateSchema ? (
    <></>
  ) : (
    <MuiForm
      noHtml5Validate
      validator={validator}
      showErrorList="top"
      transformErrors={transformErrors}
      uiSchema={{
        'ui:submitButtonOptions': {
          norender: true
        },
        ...uiSchema
      }}
      // @ts-ignore
      schema={updateSchema}
      {...props}
    />
  );
}
