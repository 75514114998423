import { AppBar, Box, CssBaseline, Toolbar, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { NavigationDrawer } from './navigation/navigationDrawer/NavigationDrawer';
import { AppRouter, LOGIN_PATH } from './AppRouter';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { customTheme } from './theme';
import CSGLogoPath from './icons/csg-logo.svg';
import { SvgIcon } from './sharedComponents';
import { ThemeOverrides } from './theme/theme.interfaces';
import { styled } from '@mui/material/styles';
import { ProfilePopper } from './sharedComponents/ProfilePopper';
import { sendToLogin } from './sharedComponents/Login/auth.helpers';
import { ActivityTimer } from './sharedComponents/ActivityTimer/ActivityTimer';
import { useIdleTimer } from 'react-idle-timer';
import {
  readToSessionStorage,
  writeToSessionStorage
} from './sharedComponents/Login/session.storage';
import { getRefreshToken } from './api/refreshToken';
import { useEffect } from 'react';
import { AuthenticationMessages } from './constants/common.constants';

const REFRESH_TOKEN_TIMER_DURATION = 15 * 60 * 1000;

const queryClient = new QueryClient();

const AppRouterContainer = styled(Box)`
  flex-grow: 1;
  background-color: ${(props: ThemeOverrides) =>
    props.theme?.palette?.canvas.white};
  border-radius: ${({ theme }) => theme.spacing(3)} 0 0
    ${({ theme }) => theme.spacing(3)};
`;
const AppBarStyled = styled(AppBar)`
  box-shadow: none;
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
  background-color: ${(props: ThemeOverrides) =>
    props.theme?.palette?.canvas.dark};
`;
const RightHeaderStyled = styled(Box)`
  width: 30px;
  height: 64px;
  background-color: ${(props: ThemeOverrides) =>
    props.theme?.palette?.navigation.rightHeaderBackground};
`;

export function logOut(message?: string) {
  sessionStorage.clear();
  sendToLogin(LOGIN_PATH, message);
}

function App() {
  const isSessionIdExists = readToSessionStorage('starc-sessionId');
  const { pause, resume } = useIdleTimer({
    events: ['load']
  });

  //for every 15 every minutes timer to get refresh token
  useEffect(() => {
    if (isSessionIdExists) {
      const refreshIntervalTimerId = setInterval(() => {
        pause();
        getRefreshToken().then(() => {
          resume();
        });
      }, REFRESH_TOKEN_TIMER_DURATION);
      return () => {
        clearInterval(refreshIntervalTimerId);
      };
    }
  }, [isSessionIdExists, pause, resume]);

  function loadConfigFile() {
    fetch('/config.json').then((configJson) => {
      configJson.json().then((configData) => {
        writeToSessionStorage('starc-login-url', configData.loginUrl);
        writeToSessionStorage('starc-api-base-url', configData.apiBaseUrl);
        writeToSessionStorage('starc-api-bu', configData.bu);
        writeToSessionStorage('starc-client-secret', configData.clientSecret);
        writeToSessionStorage('starc-client-id', configData.clientId);
      });
    });
  }
  useEffect(() => {
    loadConfigFile();
  }, []);

  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AppBarStyled>
            <Toolbar
              sx={{
                '&.MuiToolbar-regular': {
                  paddingRight: 0,
                  paddingLeft: 1,
                  display: 'flex',
                  justifyContent: 'space-between'
                }
              }}
            >
              <Box>
                <SvgIcon
                  src={CSGLogoPath}
                  sx={({ palette }) => ({
                    fill: palette.common.white
                  })}
                />
              </Box>
              <Box display="flex">
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  color={(theme) => theme.palette.common.white}
                  noWrap
                  sx={{
                    fontSize: '0.875rem',
                    display: 'flex',
                    alignSelf: 'center',
                    marginRight: 1,
                    fontFamily: 'Noto Sans',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '19px'
                  }}
                >
                  Advanced Rating and Charging Configuration
                </Typography>
                <RightHeaderStyled />
                <ProfilePopper
                  logOutClick={() =>
                    logOut(AuthenticationMessages.successfulLogOut)
                  }
                />
              </Box>
            </Toolbar>
          </AppBarStyled>
          <Box
            sx={{
              display: 'flex',
              height: '100%'
            }}
          >
            <NavigationDrawer />
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                bgcolor: 'canvas.dark'
              }}
            >
              <Toolbar />
              <AppRouterContainer>
                <AppRouter />
              </AppRouterContainer>
            </Box>
          </Box>
        </BrowserRouter>
        {!!isSessionIdExists && <ActivityTimer />}
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
