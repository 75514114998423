import { Box, styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import React from 'react';

const Centered = styled(Box)`
  position: absolute;
  left: 50%;
  top: 50%;
`;

interface PageLoadingProps {
  /** is the loding spinner shown */
  open: boolean;
}

/** Modal with a loading spinner */
export function PageLoading({ open }: PageLoadingProps) {
  return (
    <Modal disableEnforceFocus disableAutoFocus open={open}>
      <Centered>
        <CircularProgress />
      </Centered>
    </Modal>
  );
}
