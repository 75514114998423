import * as logger from './logger';

const supportsSessionStorage = () => {
  return window && window.sessionStorage;
};

/** Read a value from session storage. */
export function readToSessionStorage(key: string) {
  let item;
  if (supportsSessionStorage()) {
    try {
      item = JSON.parse(window.sessionStorage.getItem(key) || 'null');
    } catch (err) {
      logger.error(`An error occurred reading "${key}" from session storage.`);
    }
  } else {
    logger.error(
      `Session Storage is not detected for the browser. Cannot read "${key}".`
    );
  }
  return item;
}

/** Write data to session storage. */
export function writeToSessionStorage(key: string, json: object | string) {
  if (supportsSessionStorage()) {
    window.sessionStorage.setItem(key, JSON.stringify(json));
  } else {
    logger.error(
      `Session Storage is not detected for the browser. Cannot write "${key}".`
    );
  }
}

/** Remove data from session storage. */
export function removeToSessionStorage(key: string) {
  if (supportsSessionStorage()) {
    window.sessionStorage.removeItem(key);
  } else {
    logger.error(
      `Session Storage is not detected for the browser. Cannot remove "${key}".`
    );
  }
}
