import { Box, Grid, Paper } from '@mui/material';
import {
  ArrayFieldTemplateItemType,
  FormContextType,
  RJSFSchema,
  StrictRJSFSchema
} from '@rjsf/utils';
import Button from '@mui/material/Button';
import { SvgIcon } from '../../Icon';
import trashIconPath from '../../../icons/trash-bin.svg';

/** The `ArrayFieldItemTemplate` component is the template used to render an items of an array.
 *
 * @param props - The `ArrayFieldTemplateItemType` props for the component
 */
export default function ArrayFieldItemTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: ArrayFieldTemplateItemType<T, S, F>) {
  const {
    children,
    disabled,
    hasToolbar,
    hasRemove,
    index,
    onDropIndexClick,
    readonly,
  } = props;

  return (
    <Grid container={true} alignItems="center">
      <Grid item={true} xs style={{ overflow: 'auto' }}>
        <Paper elevation={2} sx={{ boxShadow: 0, background: 'inherit' }}>
          <Box>
            {children}
            {hasToolbar && hasRemove && (
              <Box display="flex" justifyContent="flex-end">
                <Button
                    data-cy="remove"
                    variant="text"
                    disabled={disabled || readonly}
                    type="button"
                    onClick={onDropIndexClick(index)}
                    color='inherit'
                    startIcon={<SvgIcon src={trashIconPath} sx={{ height: '14px', width: '14px', '& svg': {display: 'flex'}}} />}>
                      Remove
                  </Button>
              </Box>
            )}
          </Box>
        </Paper>
      </Grid>
    
    </Grid>
  );
}
